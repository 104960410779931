.lead-otp-verify-container {
    background: white;
    padding: 30px;
    border-radius: 10px;
    width: 400px; /* Increased width */
    text-align: center;
    position: relative;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: auto; /* Center the modal */
}

.lead-otp-verify-input-container {
    margin: 20px 0;
}

.lead-otp-verify-otp-input {
    width: 100%;
    padding: 10px;
    font-size: 1.5rem;
    text-align: center;
    border: 2px solid #ddd;
    border-radius: 5px;
    letter-spacing: 0.5rem;
    box-sizing: border-box;
}

.lead-otp-verify-verify-button {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 10px;
}

.lead-otp-verify-verify-button:disabled {
    background-color: #aaa;
    cursor: not-allowed;
}

.lead-otp-verify-verify-button:not(:disabled):hover {
    background-color: #0056b3;
}


