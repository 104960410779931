.generalSettingsContent {
    padding: 30px;

    section {
        background: white;
        border-radius: 12px;
        padding: 25px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.09);
        margin-bottom: 30px;
    }

    h2 {
        color: #333;
        font-size: 20px;
        margin-bottom: 25px;
        font-weight: 600;
        position: relative;
        padding-bottom: 10px;
    }

    h2:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 40px;
        height: 3px;
        background: #473391;
        border-radius: 2px;
    }

    h5 {
        color: #555;
        font-weight: 500;
        min-width: 100px;
        margin-right: 15px !important;
        margin-top:15px;
    }

    .row {
        margin-bottom: 20px;
    }

    .form-input-new {
        height: 42px;
        width: 100%;
        border: 1px solid #e0e0e0;
        border-radius: 8px;
        padding: 10px 15px;
        transition: all 0.3s ease;
        background: #f8f9fa;
    }

    .form-input-new:focus {
        background: white;
        border-color: #473391;
        box-shadow: 0 0 0 3px rgba(71, 51, 145, 0.1);
    }

    .upload-button {
        height: 42px;
        padding: 0 20px;
        margin-top: 0;
        margin-left: 15px;
        white-space: nowrap;
        background-color: #473391;
        color: white;
        border: none;
        border-radius: 8px;
        cursor: pointer;
        font-size: 14px;
        transition: all 0.3s ease;
        font-weight: 500;
    }

    .upload-button:hover {
        background-color: #6c5ba7;
        transform: translateY(-1px);
        box-shadow: 0 4px 8px rgba(71, 51, 145, 0.2);
    }

    /* Select styles */
    .select__control {
        min-height: 42px !important;
        border-color: #e0e0e0 !important;
        border-radius: 8px !important;
        background: #f8f9fa !important;
        box-shadow: none !important;
        transition: all 0.3s ease !important;
    }

    .select__control:hover {
        border-color: #473391 !important;
    }

    .select__control--is-focused {
        background: white !important;
        border-color: #473391 !important;
        box-shadow: 0 0 0 3px rgba(71, 51, 145, 0.1) !important;
    }

    .select__menu {
        border-radius: 8px !important;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1) !important;
        overflow: hidden;
    }

    .select__option {
        padding: 10px 15px !important;
    }

    .select__multi-value {
        background-color: #473391 !important;
        border-radius: 4px !important;
        padding: 2px !important;
    }

    .select__multi-value__label {
        color: white !important;
        font-size: 13px !important;
    }

    .select__multi-value__remove {
        color: white !important;
        cursor: pointer !important;
        &:hover {
            background-color: rgba(255, 255, 255, 0.1) !important;
            color: white !important;
        }
    }

    /* Features section */
    .features-container {
        display: flex;
        gap: 15px;
        margin-top: 15px;
    }

    .features-inputs {
        display: flex;
        flex-wrap: wrap;
        
        width: 100%; 
    }

    .feature-flex{
        position: relative;
        margin-bottom: 15px;
        margin-right: 40px;
        display: flex;
        align-items: center;
    }
    .remove-field {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: -30px;
        top: 35px;
        background: none;
        border: 1px solid gray;
        border-radius: 50%;
        width: 24px;
        height: 24px;
        color: gray;
        cursor: pointer;
        padding: 5px;
        opacity: 0.7;
        transition: opacity 0.2s;

        &:hover {
            color: #dc3545;
            border: 1px solid #dc3545;
        }
    
}

    .add-new-field-btn {
        background: #f8f9fa;
        border: 1px dashed #473391;
        color: #473391;
        padding: 8px 16px;
        border-radius: 8px;
        cursor: pointer;
        font-size: 14px;
        width: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        transition: all 0.3s ease;
        font-weight: 500;
        margin-top: 5px;

        &:hover {
            background-color: #473391;
            color: white;
            transform: translateY(-1px);
            box-shadow: 0 4px 8px rgba(71, 51, 145, 0.2);
        }
    }

    .clear-file {
        position: absolute;
        right: 25px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        color: #473391;
        font-size: 18px;
        opacity: 0.7;
        transition: all 0.2s ease;
    }

    .clear-file:hover {
        opacity: 1;
    }

    /* Adjust flex containers */
    /* .col-md-4, .col-md-6 {
        display: flex;
        align-items: center;
        gap: 15px;
    } */

    /* Adjust input container */
    .form-input-new-container {
        flex: 1;
        position: relative;
    }
    .form-input-new-container label {
        font-size: 14px !important;
        margin-bottom: 5px;
    }

    /* Adjust Select container */
    .basic-multi-select {
        width: 100%;
    }

    /* Testimonials section */
    .reviews-container {
        display: flex;
        gap: 15px;
        margin-top: 15px;
    }

    .reviews-inputs {
        flex: 1;
    }
    .cattabs{
        display: flex;
        gap: 10px;
    }
    .cattab {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 42px;
        border: 1px solid #ddd;
        border-radius: 30px;
        cursor: pointer;
        padding: 10px 20px;
        background-color: #fff;
        transition: all 0.3s ease-in-out;
    }
    .cattab.active {
        background: #473391;
        color: #fff;
    }
    .cattab:hover {
        background: #473391;
        color: #fff;
    }
    

    .review-item {
        background: #f8f9fa;
        border: 1px solid #e0e0e0;
        border-radius: 8px;
        padding: 15px;
        margin-bottom: 15px;
        position: relative;
        width: 99%;
    }
    .review-item label {
        font-size: 16px;
        margin-bottom: 5px;
    }

    .review-field {
        margin-bottom: 15px;

        &:last-child {
            margin-bottom: 0;
        }

        .image-upload {
            display: flex;
            gap: 15px;
        }
    }

    textarea.form-input-new {
        height: 100px;
        resize: vertical;
        min-height: 100px;
        background: #fff;
    }

    /* .remove-field {
        position: absolute;
        right: -30px;
        top: 15px;
    } */

    .testimonial-block {
        background: #f8f9fa;
        border: 1px solid #e0e0e0;
        border-radius: 8px;
        padding: 20px;
        margin-bottom: 20px;
        position: relative;
        width: 99%;
    }

    .remove-testimonial {
        position: absolute;
        right: 15px;
        top: 15px;
        background: none;
        border: none;
        color: #dc3545;
        cursor: pointer;
        font-size: 20px;
        padding: 5px;
        opacity: 0.7;
        transition: opacity 0.2s;

        &:hover {
            opacity: 1;
        }
    }

    /* Update existing reviews styles */
    .reviews-container {
        margin-top: 20px;
    }

    .review-item {
        background: white;  /* Change to white for contrast */
    }

    .save-button-container {
        display: flex;
        justify-content: flex-end;
        margin-top: 20px;
        padding-bottom: 20px;
    }

    .save-section-btn {
        background-color: #473391;
        color: white;
        padding: 10px 24px;
        border: none;
        border-radius: 8px;
        cursor: pointer;
        font-size: 14px;
        font-weight: 500;
        transition: all 0.3s ease;

        &:hover {
            background-color: #6c5ba7;
            transform: translateY(-1px);
            box-shadow: 0 4px 8px rgba(71, 51, 145, 0.2);
        }

        &:disabled {
            background-color: #ccc;
            cursor: not-allowed;
            transform: none;
            box-shadow: none;
        }
    }

    .service-details {
        background: #f8f9fa;
        border: 1px solid #e0e0e0;
        border-radius: 8px;
        padding: 20px;
        margin-top: 20px;
        margin-bottom: 25px;
        position: relative;

        h5 {
            margin-bottom: 15px !important;
            color: #473391;
            font-weight: 600;
        }

        &:not(:first-of-type) {
            border-top: 3px solid #473391;
        }
    }

    .partner-item {
        background: white;
        border: 1px solid #e0e0e0;
        border-radius: 8px;
        padding: 15px;
        margin-bottom: 15px;
        position: relative;
    }

    .banking-partners {
        margin-top: 20px;
    }

    .stats-section {
        margin-top: 25px;
    }

    .stat-item {
        border: 1px solid #e0e0e0;
        border-radius: 8px;
        padding: 15px 15px 0px 15px;
        margin-bottom: 15px;
        position: relative;
        width: 99%;
    }

    /* Calculator Section */
    .calculator-options {
        background: #f8f9fa;
        border: 1px solid #e0e0e0;
        border-radius: 8px;
        padding: 20px;
        margin-bottom: 25px;
    }

    .checkbox-container {
        display: flex;
        align-items: center;
        gap: 12px;
        margin-bottom: 10px;

        label {
            color: #333;
            font-size: 14px;
            font-weight: 500;
            cursor: pointer;
            user-select: none;
        }
    }

    .custom-checkbox {
        width: 18px;
        height: 18px;
        border-radius: 4px;
        border: 2px solid #473391;
        appearance: none;
        -webkit-appearance: none;
        cursor: pointer;
        position: relative;
        background: white;
        transition: all 0.2s ease;

        &:checked {
            background: #473391;

            &:after {
                content: '';
                position: absolute;
                left: 5px;
                top: 2px;
                width: 5px;
                height: 10px;
                border: solid white;
                border-width: 0 2px 2px 0;
                transform: rotate(45deg);
            }
        }

        &:hover {
            border-color: #6c5ba7;
        }

        &:disabled {
            background: #e0e0e0;
            border-color: #ccc;
            cursor: not-allowed;
        }
    }

    /* Footer Section */
    .footer-partner-logos,
    .footer-quick-links,
    .footer-other-links,
    .footer-address {
        background: #f8f9fa;
        border: 1px solid #e0e0e0;
        border-radius: 8px;
        padding: 20px;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .partner-logo-item,
    .footer-link-item {
        background: white;
        border: 1px solid #e0e0e0;
        border-radius: 8px;
        padding: 15px;
        margin-bottom: 15px;
        position: relative;
        width: 99%;
    }
}

.domainSettingsContent {
    padding: 30px;
    
    section {
        background: white;
        border-radius: 12px;
        padding: 25px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
        margin-bottom: 30px;
    }
    
    h2 {
        color: #333;
        font-size: 20px;
        margin-bottom: 25px;
        font-weight: 600;
        position: relative;
        padding-bottom: 10px;
    }

    h2:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 40px;
        height: 3px;
        background: #473391;
        border-radius: 2px;
    }

    h5 {
        color: #555;
        font-weight: 500;
        min-width: 100px;
        margin-right: 15px !important;
        margin-top: 15px;
    }

    .row {
        margin-bottom: 20px;
    }

    .form-input-new {
        height: 42px;
        width: 100%;
        border: 1px solid #e0e0e0;
        border-radius: 8px;
        padding: 10px 15px;
        transition: all 0.3s ease;
        background: #f8f9fa;
    }

    .form-input-new:focus {
        background: white;
        border-color: #473391;
        box-shadow: 0 0 0 3px rgba(71, 51, 145, 0.1);
    }

    .upload-button {
        height: 42px;
        padding: 0 20px;
        margin-top: 0;
        margin-left: 15px;
        white-space: nowrap;
        background-color: #473391;
        color: white;
        border: none;
        border-radius: 8px;
        cursor: pointer;
        font-size: 14px;
        transition: all 0.3s ease;
        font-weight: 500;
    }

    .upload-button:hover {
        background-color: #6c5ba7;
        transform: translateY(-1px);
        box-shadow: 0 4px 8px rgba(71, 51, 145, 0.2);
    }
    
    
    
    .basic-select {
        width: 100%;
    }
    
    /* Select styles */
    .select__control {
        min-height: 42px !important;
        border-color: #e0e0e0 !important;
        border-radius: 8px !important;
        background: #f8f9fa !important;
        box-shadow: none !important;
        transition: all 0.3s ease !important;
    }

    .select__control:hover {
        border-color: #473391 !important;
    }

    .select__control--is-focused {
        background: white !important;
        border-color: #473391 !important;
        box-shadow: 0 0 0 3px rgba(71, 51, 145, 0.1) !important;
    }
    
    .image-upload {
        display: flex;
        gap: 15px;
    }
    
    .save-button-container {
        display: flex;
        justify-content: flex-end;
        margin-top: 20px;
        padding-top: 20px;
    }

    .save-section-btn {
        background-color: #473391;
        color: white;
        padding: 10px 24px;
        border: none;
        border-radius: 8px;
        cursor: pointer;
        font-size: 14px;
        font-weight: 500;
        transition: all 0.3s ease;

        &:hover {
            background-color: #6c5ba7;
            transform: translateY(-1px);
            box-shadow: 0 4px 8px rgba(71, 51, 145, 0.2);
        }

        &:disabled {
            background-color: #ccc;
            cursor: not-allowed;
            transform: none;
            box-shadow: none;
        }
    }
}

