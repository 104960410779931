.header {
  position: fixed;
  top: 0;
  width: 100%;
  background: #473391;
  z-index: 99;
  border-bottom: 2px solid rgb(214, 213, 213);
}

.headericon {
  display: flex;
  align-items: center;
  padding: 16px;
  color: #fff;
  height: 45px;
  background-color: #473391;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 99;
}
/* body {
  padding-top: 56px;
} */
.menuflex{
  display: flex;
  align-items: center;
  gap: 20px;
}
.navbar{
  position: fixed;
  z-index: 99;
  background: #473391;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  max-width: 250px;
  box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.27) ;
  display: block;
  opacity: 0;
  visibility: hidden;
  transform: translateX(-300px);
  will-change: transform;
  transition: all 0.3s;
  &.navbaropen{
      opacity: 1;
      visibility: visible;
      transform: translateX(0px);
  }
  a, .navitem{
      display: block;
      padding: 8px 16px;
      color:black;
      position: relative;
      justify-content: center;
      align-items: center;
  }
}
.navbackdrop {
  position: fixed;
  z-index: 98;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.3s, opacity 0.3s;
}
.navbackdropopen {
  visibility: visible;
  opacity: 1;
}
.hidemobileheader{
  display: none;
}
.hidepcheader{
  display: block;
}
.payout-menu-item{
  padding: 0px;
  position: absolute;
  top: 80%;
  height: 100%;
}
.payout-menu-card{
  display: flex;
  align-items: baseline;
  justify-content: space-around;
  background: white;
  border-radius: 10px;
  color: black !important;
  padding: 10px;
  font-size: 12px;
}
.payout-menu-card>div>span{
  margin-right: 10px;
}

.payout-menu-card>div:nth-child(2){
  color: #473391;
  font-weight: 500;
  cursor: pointer;
}
header .navbar-nav {
  height: 100%;
  margin-top: 20px;
  margin-left: auto;
  float: inherit;
}
.navbar-nav {
  padding: 20px;
  height: 90%;
}
.navbar-nav .navbar-nav li {
  width: 100%;
  float: left;
}
.navbar-nav li a {
  color: #fff;
  opacity: 0.7;
  padding:10px;
  margin-bottom: 20px;
  display: block;
  border-bottom: solid 3px transparent;
}
.navbar-nav li a:hover, .navbar-nav li a:focus, .navbar-nav li a:active {
  color: white;
}
.navbar-nav li a.active {
  background-color: #6952BD;
  border-radius: 10px;
  padding:10px;
  color: white;
  opacity: 1;
}
.navbar-nav li a:hover {
  background-color: #6952BD;
  padding:10px ;
  opacity: 1;
  border-radius: 10px;
}
.navbar-nav li img {
  display: inline-block;
  margin-right: 8px;
}
.navbar-nav li > .dropdown-menu li .active {
  border-bottom: solid 3px transparent;
  color: rgba(0, 0, 0, 0.54);
}
.navbar-nav li > .dropdown-menu li :hover {
  color: rgb(2, 2, 253);
}
.closemenunew{
  display: flex;
  align-items: center;
  justify-content: end;
  font-size: 20px;
  color: #fff;
  cursor: pointer;
  margin-bottom: 20px;
}
@media(min-width:768px){
  .hidepcheader{
    display: none;
  }
  .hidemobileheader{
    display: block;
  }
  header {
    /* background: #7281f1; */
    background: #473391;
    /* padding: 20px; */
    /* align-items: center; */
    width: 100%;
  }
  header .navbar-brand {
    float: left;
    max-width: 100%;
    max-height: 32px;
    margin: 10px 10px;
  }
  header .navbar-brand img {
    max-height: 32px;
  }
  header nav {
    height: 70%;
    margin-top: 20px;
    margin-left: auto;
    float: inherit;
  }
  header nav ul {
    padding: 20px;
    height: 75%;
  }
  header nav .navbar-nav li {
    width: 100%;
    float: left;
  }
  header nav .navbar-nav li a {
    color: #fff;
    opacity: 0.7;
    padding:10px;
    margin-bottom: 20px;
    display: block;
    border-bottom: solid 3px transparent;
  }
  header nav .navbar-nav li a:hover, header nav .navbar-nav li a:focus, header nav .navbar-nav li a:active {
    color: white;
  }
  header nav .navbar-nav li a.active {
    background-color: #6952BD;
    border-radius: 10px;
    padding:10px;
    color: white;
    opacity: 1;
  }
  header nav .navbar-nav li a:hover {
    background-color: #6952BD;
    padding:10px ;
    opacity: 1;
    border-radius: 10px;
  }
  header nav .navbar-nav li img {
    display: inline-block;
    margin-right: 8px;
  }
  header nav .navbar-nav li > .dropdown-menu li .active {
    border-bottom: solid 3px transparent;
    color: rgba(0, 0, 0, 0.54);
  }
  header nav .navbar-nav li > .dropdown-menu li :hover {
    color: rgb(2, 2, 253);
  }
  header .hamburger-icon {
    font-size: 42px;
    color: #ffffff;
    display: none;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
  }
  header.header-fixed {
    position: fixed;
    z-index: 99;
    width: 250px;
    height: 100vh;
    left: 0px;
    -webkit-box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.37);
    -moz-box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.37);
    -ms-box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.37);
    -o-box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.37);
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.37);
  }
  /* .dropdown a{
   color: #fff !important;
  } */
  .headermenulogout{
    color: #000;
  }
  .navbar-nav{
    position: relative;
  }

  
}


