/* YourComponent.css */
.toggle-switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 24px;
  }
  
  .toggle-switch input {
    display: none;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 34px;
  }
  
  .slider:before {
    position: absolute;
    content: '';
    height: 18px;
    width: 18px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
  }
  
  input:checked + .slider {
    background-color: #2196f3;
  }
  
  input:checked + .slider:before {
    transform: translateX(26px);
  }
  input:checked + .slider {
    background-color: #16DBCC;
}

.card label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 8px;
    opacity: 2.5;
    font-size: 13px;
}


.card-new {
  background: #ffffff;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  border-radius: 20px;
  width: 70%;
  -webkit-box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  -ms-box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  -o-box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  margin-bottom: 15px;
  position: relative;
}
.card-new-body{
  padding: 40px;
}

.property-card{
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 50px;
}
.mainheading{
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 16px;
}
.incomeflex{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.propertybtn{
  display: flex;
  align-items: end;
  justify-content: end;
  margin-bottom: 20px;
}
.brecard{
  position: relative;
  background: #fff;
  padding:20px;
  border-bottom: 3px solid #D9D9D9;
}
.brelisting{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.brelistingwidth{
  display: flex;
  width: 80%;
}
.bredetails{
  width: 20%;
  margin-right: 20px;
}
.brename{
  font-size: 12px;
  margin-bottom: 10px;
}
.breheading{
  font-size: 16px;
  font-weight: 600;
}
.brestatusbtn{
 text-align: right;
}
.brestatus{
  display: flex;
  align-items: center;
  background: #17BC8A;
  border-radius: 30px;
  font-size: 15px;
  font-weight: 600;
  height: 42px;
  padding: 10px 30px;
  color: white;
}

.brefinancecard{
  padding: 20px 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #E6EFF5;
  margin-bottom: 50px;
}
.financestatus{
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 35%;
}
.finencerflex{
  display: flex;
  align-items: center;
}
.bregreendot{
  width: 12px;
  height: 12px;
  background: #17BC8A;
  border-radius: 50%;
  margin-right: 10px;
}
.breyellowdot{
  width: 12px;
  height: 12px;
  background: #EDB259;
  border-radius: 50%;
  margin-right: 10px;
}
.brereddot{
  width: 12px;
  height: 12px;
  background: #F37171;
  border-radius: 50%;
  margin-right: 10px;
}
.bretable{
  padding: 20px 50px;
}
.table-bre thead th,
.table-bre thead td {
  border-bottom-width: 1px;
  padding: 10px;
  font-weight: 600;
  color: #6147FF;
  text-align: left;
}
.table-bre thead th{
  border-bottom: none;
}
.table-bre tr{
  border-bottom: 1px solid #d7bcbc
}

.brenewcard::before{
  background: linear-gradient(108.84deg, #D8F4E8 -15.26%, #DCD6F6 93.77%);
  content: "";
  width: 20%;
  height: 40%;
  filter: blur(100px);
  top: 50px;
  left: 0;
  position: absolute;

}
.breemicard{
 display: flex;
 align-items: center;
 justify-content: space-evenly;
 margin-bottom: 50px;
 position: relative;
}

.breemibox{
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border: 1px solid rgba(0, 0, 0, .1);
  padding: 10px;
  width: 40%;
  border-radius: 20px;
}
.breloan{
  font-size: 14px;
  opacity: 0.7;
  margin-bottom: 6px;
}
.breamount{
  font-size: 20px;
  font-weight: 600;
}
.breborder{
  width: 1px;
  height: 40px;
  background: rgba(0, 0, 0, 0.5);
}
.bretablecard{
  background: #fff;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  padding: 20px;
  border-radius: 20px;
  margin-bottom: 20px;
}

/* .table-bre td{
  background: #fff1d6;
} */

.hard-reject {
  background: #e9c4c4
}

.soft-reject {
  background:#fff1d6;
}
.breoffercard{
  padding: 20px;
  position: relative;
}
.breofferheading{
  font-size: 18px;
  font-weight: 600;
}
.offerlistingcard{
  display: flex;
}
.offerbox{
  padding: 15px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.27) ;
  margin: 20px 0px;
}
.offerwidth{
  width: 75%;
  padding: 0px 50px;
  border-left: 1px solid #000;
  border-right: 1px solid #000;
}
.offerimg{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10%;
  /* border-right: 1px solid #000; */
  margin-right: 20px;
}
.offerbox img{
  border-radius: 10px;
  
}
.offercardflex{
  display: flex;
  align-items: center;
}
.offerlisting{
  width: 25%;
}
.offermeterflex{
  display: flex;
  gap: 20px;
  justify-content: space-between;
  margin-top: 20px;

}
.offermeter{
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #F8F6F1;
  border: 1px solid rgb(170, 170, 170);
  height: 32px;
  padding: 5px 10px;
  border-radius: 10px;
  width: 48%;
}
.offermeternew{
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #F8F6F1;
  border: 1px solid #F3CC30;
  height: 32px;
  padding: 5px 10px;
  border-radius: 10px;
  width: 100%;
  cursor: pointer;
}
.offerdocument{
  display: flex;
  align-items: center;
  justify-content:center;
  background: #F8F6F1;
  border: 1px solid #F3CC30;
  height: 32px;
  padding: 5px 10px;
  border-radius: 10px;
  width: 15%;
  cursor: pointer;
}
.offerdocumentname{
  font-size: 10px;
}
.confidencename{
  font-size: 10px;
  margin-right: 10px;
}

.offerdocumentlist ul{
  width: 100%;
}
.offerdocumentlist li {
  margin-bottom: 20px;
  display: block;
  border: 1px solid #e7e7e7;
  border-radius: 10px;
  max-height: 175px;
  overflow-y: auto;
}
.documentheading{
  font-size: 14px;
  position: relative;
  margin: 0px;
  padding: 16px 10px;
}
.documenticon{
  position: absolute;
  right: 20px;
}
.subheading{
  padding: 10px;
  border: none !important;
  margin-bottom: 0px !important;
  font-size: 12px;
}
.rotated-icon {
  transition: transform 0.1s ease; 
}
.rotated-icon.rotate-180 {
  transform: rotate(180deg);
}
.commonchance{
  font-size: 10px;
  margin-right: 10px;
}
.offerapplynow{
  padding: 0px 50px;
}
.offerbtn{
  background: #6147FF;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  border: none;
  color: #fff;
  font-size: 10px;
  width: 110px;
  cursor: pointer;
}
.offerlink{
  border: 1px solid lightgray;
  display: flex;
  align-items: center;
  text-align: center;
  height: 32px;
  width: 110px;
  border-radius: 6px;
  color: #6147FF;
  font-size: 8px;
  margin-top: 10px;
  cursor: pointer;
  img{
    margin-left: 10px;
  }
}


.toggle-checkbox {
  display: none;
}
.toggle-checkbox:checked + label .offermeternew img {
  transform: rotate(180deg);
}
.offermeternew .tips-list {
  display: none;
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  z-index: 1;
  background: #F8F6F1;
  border: 1px solid #F3CC30;
  border-radius: 10px;
  padding: 10px;
  width: 100%;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
  li{
    font-size: 10px;
    margin-bottom: 10px;
  }
}
.toggle-checkbox:checked + label .offermeternew .tips-list {
  display: block;
}
.offermeternew {
  position: relative;
}
.offerthankyou{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.offerwhatsapp{
  display: flex;
  flex-direction: column;
  padding: 0px 50px;
  img{
    height: 80px;
  }
}
.thankyouheading{
  font-size: 24px;
  font-weight: 600;
  margin: 40px 0px;
}
.whatsappheading{
  font-size: 18px;
  font-weight: 600;
  margin: 20px 0px;
}
.thankyoupara{
  font-size: 16px;
}
.offerback{
  color: #6147FF;
  font-size: 16px;
  border-bottom: 1px solid #6147FF ;
}
.whatsappnumber{
  display: flex;
  align-items: center;
  border: 1px solid lightgray;
  height: 42px;
  border-radius: 10px;
  padding-left: 10px;
}
.whatsappnumber span{
  display: none;
}
.numberborder{
  border-right: 1px solid #000;
  padding-right: 10px;
  margin-right: 10px;
}
.whatsappnumber input{
  border:none;
  width: 100% !important;
  height: 40px;
}
.sendbtncard{
  display: flex;
  align-items: center;
  justify-content: center;
}
.sendbtn{
  background: #6147FF;
  height: 42px;
  border: none;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  width: 200px;
  border-radius: 10px;
  cursor: pointer;
}

.modal-popup{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}
.modal-context{
  height: 510px;
  background: #fff;
  padding: 45px;
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  max-width: 94%;
  max-height: 80%;
  overflow-y: auto;
  position: relative;
}
.modal-height{
  height: auto;
  background: #fff;
  padding: 45px;
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  max-width: 94%;
  max-height: 80%;
  overflow-y: auto;
  position: relative;
}
.close{
  position: absolute;
  top: 10px;
  right:27px;
  font-size: 30px;
  cursor: pointer;
}

.inputrange input[type="range"] {
  -webkit-appearance: none;
  width: 120px;
  height: 10px;
  margin-right: 10px;
  background: linear-gradient(to right, #EB8F82 33.33%, #698BDA 33.33%, #698BDA 66.66%, #87CE76 66.66%);
  border-radius: 5px;
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;
  
}

.inputrange input[type="range"]:hover {
  opacity: 1;
}

.inputrange input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 5px;
  height: 20px;
  background: #000;
  border-radius: 10px;
  cursor: pointer;
}

.inputrange input[type="range"]::-moz-range-thumb {
  width: 5px;
  height: 20px;
  background: #000;
  border-radius: 10px;
  cursor: pointer;
}

.amount-in-words {
  margin-top: -20px;
  font-style: italic;
  color: #555;
}

.amount-in-words span {
  color: #333;
  font-size: 12px;
}

.green-tick {
  width: 20px; /* Adjust as needed */
  height: 20px; /* Adjust as needed */
  background-color: green;
  border-radius: 50%;
  position: relative;
}

.green-tick::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
  width: 70%;
  height: 30%;
  border-bottom: 3px solid white;
  border-right: 3px solid white;
}
.pandetailsbtn{
  display: flex;
  align-items: center;
  justify-content: center;
  background: #434150;
  height: 42px;
  color: #fff;
  border-radius: 10px;
  cursor: pointer;
}

/* Main sidebar container with overlay effect */
.rightsidebar {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 30%; 
  background-color: rgb(255, 255, 255); 
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.3);
  overflow-y: auto;
  transform: translateX(100%); 
  transition: transform 0.3s ease;
  z-index: 1000;
  backdrop-filter: blur(20px); 
  z-index: 10;
}

.rightsidebar-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(58, 57, 57, 0.5); 
  backdrop-filter: blur(2px); 
  transition: opacity 0.3s ease, visibility 0.3s ease;
  z-index: 5;
}

.rightsidebar.open {
  padding: 20px;
  transform: translateX(0); 
}

/* Close button inside the sidebar */
.rightsidebar .close {
  position: absolute;
  top:30px;
  font-size: 24px;
  color: #333;
  cursor: pointer;
  background: none;
  border: none;
  font-weight: bold;
}

.rightsidebar-header {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  /* align-items: center; */
  padding: 16px;
  padding-bottom: 40px;
  /* position: sticky; */
  top: 0;
  background-color: #fff;
}

.rightsidebar-title {
  font-size: 15px;
  font-weight: 500;
  margin: 0;
}

/* Invoice Card Style */

.invoice-head{
  border-bottom: 0.5px solid #eaeaea;
  padding-bottom: 20px;
}

.flex{
  display: flex;
}
.flex-row{
  flex-direction: row;
}
.justify-space-between{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.justify-space-around{
  justify-content: space-around;
}
.flex-col{
  flex-direction: column;
}
.gap-10px{
  gap:10px
}
.font-11px{
  font-size: 11px;
}
.font-13px{
  font-size: 13px;
}
.font-14px{
  font-size: 14px;
}
.font-weight-500{
  font-weight: 500;
}
.font-weight-400{
  font-weight: 400;
}


.invoiceTag{
  color: white;
  background-color: #1ac18f;
  border-radius: 5px;
  text-align: center;
  padding: 5px;
  font-size: 12px;
  margin-top: 5px;
}
.invoiceTagpending{
  color: white;
  background-color: red;
  border-radius: 5px;
  text-align: center;
  padding: 5px;
  font-size: 12px;
  margin-top: 5px;
}
.font-btn-color{
  font-size: 12px;
  font-weight: 500;
  color:#473391;
  cursor: pointer;
}



.notes-head{
  border: 1px solid #eaeaea;
  margin: 10px;
  border-radius: 15px;
  /* padding-bottom: 20px; */
}
.notes-header{
  font-size: 12px;
  font-weight: 500;
  margin: 10px;
  padding-top: 10px;
}

.notes-inner-box{
  border-bottom: 1px solid #eaeaea;
  padding: 10px;
}
.notes-bottom{
  padding:10px;
  margin:0 20px; 
}
.notes-inner-detail-box{
  border-bottom: 1px solid #eaeaea;
  border-top: 1px solid #eaeaea;
  padding: 10px;
}
.breakupTag{
  border-radius: 5px;
  padding: 2px 10px;
  font-size: 12px;
}
.tag-purple{
  background-color:#8170ff ;
}
.tag-yellow{
  background-color: #f6eab6;
}
.color-white{
  color: white;
}
.invoice-icon{
  padding: 10px;
  background-color: #EBF2F9;
  border-radius: 50%;
}
.bank-icon{
  padding: 10px;
  background-color: #EBF2F9;
  border-radius: 50%;
  height: 70%;
}

.accordion-icon {
  transition: transform 0.3s ease;
  display: inline-block;
  margin-left: 5px;
}

.rotate {
  transform: rotate(180deg);
}

.right-arrow{
  width: 7px;
  cursor: pointer;
}
.bottomoverlay {
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  z-index: 1000;
}

.bottomfixheight {
  max-height: 500px;
  overflow-y: auto;
}

.bottommodal {
  width: 100%;
  max-width: 600px;
  background: #fff;
  border-radius: 16px 16px 0 0;
  padding: 20px;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.2);
  position: relative;
}

.bottomcloseButton {
  position: absolute;
  top: 12px;
  right: 16px;
  font-size: 24px;
  cursor: pointer;
}
