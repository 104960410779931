/* ********************* React ToolTip **************** */

.no-blur-tooltip {
 
    opacity: 1 !important;
     /* Use a subtle shadow */
     box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);

  /* Optional: give it a border, border-radius, etc. */
  border: 1px solid #ccc;
  border-radius: 12px;
   
  }
  

/* ********************* Loading css **************** */
.loadingsection{
    width: 100%;
    height: 10vh;
    display: grid;
    place-items: center;
}
.newloading {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .newloading div {
    animation: newloading 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 40px 40px;
  }
  .newloading div:after {
    content: " ";
    display: block;
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: grey;
    margin: -4px 0 0 -4px;
  }
  .newloading div:nth-child(1) {
    animation-delay: -0.036s;
  }
  .newloading div:nth-child(1):after {
    top: 63px;
    left: 63px;
  }
  .newloading div:nth-child(2) {
    animation-delay: -0.072s;
  }
  .newloading div:nth-child(2):after {
    top: 68px;
    left: 56px;
  }
  .newloading div:nth-child(3) {
    animation-delay: -0.108s;
  }
  .newloading div:nth-child(3):after {
    top: 71px;
    left: 48px;
  }
  .newloading div:nth-child(4) {
    animation-delay: -0.144s;
  }
  .newloading div:nth-child(4):after {
    top: 72px;
    left: 40px;
  }
  .newloading div:nth-child(5) {
    animation-delay: -0.18s;
  }
  .newloading div:nth-child(5):after {
    top: 71px;
    left: 32px;
  }
  .newloading div:nth-child(6) {
    animation-delay: -0.216s;
  }
  .newloading div:nth-child(6):after {
    top: 68px;
    left: 24px;
  }
  .newloading div:nth-child(7) {
    animation-delay: -0.252s;
  }
  .newloading div:nth-child(7):after {
    top: 63px;
    left: 17px;
  }
  .newloading div:nth-child(8) {
    animation-delay: -0.288s;
  }
  .newloading div:nth-child(8):after {
    top: 56px;
    left: 12px;
  }
  @keyframes newloading {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
}

  @media only screen and (max-width: 767px) {
    input[type="date"] {
      width: 100%;
      /* -webkit-appearance: none; */
      box-sizing: border-box;
      appearance: auto;
    -webkit-appearance: auto;
    -moz-appearance: auto;
    }
  }

  .react-datepicker-popper {
    z-index: 15;
}
  

.mainleadcontainer {
    background-color: rgb(227, 240, 245);
    height: 100vh;
    display: flex;
}

.mainsidebarcontainer {
    background-color: rgba(71, 51, 145, 1);
    width: 17%;
}
.LeadContainer {
    width: 100%;
    padding: 30px;
}

.myleadcontainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
}
.leadheading{
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 20px;
}
.leadheadingPYT{
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 20px;
    width: 100%;
}
.rightmyleadcontainer {
    align-items: center;
    display: flex;
}

.newleadbutton button {
    font-size: 14px;
    margin: 0 20px 0 0;
    height: 42px;
    padding: 7px 20px;
    cursor: pointer;
    border: 0;
    background-color: #6E55B8;
    color: white;
    border-radius: 30px;
}

.overflow-hidden {
    overflow: hidden;
  }
.notificationbutton {
    background-color: rgb(255, 255, 255);
    padding: 7px;
    cursor: pointer;
    border-radius: 50%;
}
.leadcontainer{
    border-radius: 10px;
    padding: 20px 30px;
    background-color: white;
}

.searchinput input {
    border-radius: 10px;
    padding: 5px 10px;
    border: 1px solid rgba(214, 204, 204, 1);
}
.filterinputs {
    display: flex;
}

/* .morefilterbtnweb{
    border: 1px solid rgba(205, 199, 199, 1);
    cursor: pointer;
    border-radius: 10px;
    background: #fff;
    padding: 7px 20px;
    font-size: 12px;
    height: 42px;
    span{
        margin-left: 10px;
    }
} */
.morefilterbtn {
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    font-weight: 500;
    font-size: 12px;
    border: 1px solid rgba(205, 199, 199, 1);
    cursor: pointer;
    border-radius: 10px;
    background: #fff;
    padding: 7px 20px;
    font-size: 12px;
    height: 42px;
    color: #5F5F5F;
    span{
        margin-left: 10px;
    }
  }
  
  #filterCard {
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    height: 0;
    overflow: hidden;
    background-color: #fff;
    border-radius: 10px;
    width: 390px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 20;
    transition: height 0.3s ease;
  }
  
  #toggleCheckbox:checked + #filterCard {
    height: auto;
    padding: 20px;
    overflow-y: auto;
    left: -50%;
  }
  
  #toggleCheckbox {
    display: none;
  }
  
  #toggleCheckbox:not(:checked) + #filterCard {
    height: 0;
    padding: 0;
    overflow: hidden;
  }
  
.searchbutton{
    width: 70px;
    height: 42px;
    cursor: pointer;
    border: none;
    font-size: 10px;
    border-radius: 30px;
    /* padding: 10px 20px; */
    color: white;
    background: #6E55B8;
}
.resetbutton{
    width: 70px;
    height: 42px;
    cursor: pointer;
    font-size: 10px;
    border: 1px solid #473391;
    border-radius: 30px;
    color: #000;
    background: #fff;
}
.exportbtn{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    min-width: 100px;
    max-width: 120px;
    height: 32px;
    cursor: pointer;
    font-size: 10px;
    border: 1px solid #473391;
    border-radius: 30px;
    color: #000;
    background: #fff;
}
.tablecontainer {
    margin: 20px 0px;
    border-radius: 10px;
    padding:20px 0px;
    background-color: white;
}
.newtabsContainer{
    display: flex;
    align-items: center;
    justify-content:space-between;
    border-bottom: 1px solid #ddd;
  }
.newtabs{
    padding: 0 20px;
    display: flex;
  }
.newtabsItem{
    margin: 0;
    padding: 10px 20px;
    cursor: pointer;
    border-bottom: none;
    display: flex;
    align-items: center;
  }
.newtabsItem.active{
    color:#473391;
    border-bottom:2px solid #473391;
    margin-bottom: -1px;
  }
.newtabContent{
    display: flex;
    align-items: center;
    gap:10px;
  }
.newtabSpan{
    margin-left: 5px;
    font-size:12px;
  }
.newtabSpan:active{
    color:#473391,
}
.all_Lead_container {
    display: flex;
    border-bottom: 1px solid grey;
    padding: 0 0 0px 0;
    justify-content: space-between;
}
.markloatbrn{
    border:1px solid #66499D;
    padding: 5px 15px;
    height: 42px;
    font-size: 12px;
    border-radius: 30px;
    background-color:#fff;
    font-family: poppins;
    cursor: pointer;       
}
.logintomovebtn{
    border: none;
    padding: 5px 15px;
    height: 42px;
    font-size: 12px;
    border-radius: 30px;
    min-width: 100px;
    background-color:#66499D;
    font-family: poppins;
    color: #fff;
    cursor: pointer;      
}
.not_assigned_lead button {
    border: 1px solid #66499D;
    padding: 5px 15px;
    height: 42px;
    font-size: 14px;
    border-radius: 30px;
    margin-right: 20px;
    background-color: transparent;
    font-family: poppins;
    cursor: pointer;
}

.table-container {
    margin: 20px;
}
table {
    width: 100%;
    border-collapse: collapse;
    font-size: 12px;
    text-align: left;
    /* overflow: hidden; */
}
th,
td {
    padding:10px;
}
table thead {
    background-color: rgba(44, 39, 66, 1);
    border-radius: 10px !important;
}

thead th {
    color: white;
    font-size: 11px;
}

tbody tr {
    border-radius: 22px;
    height: 50px;
    cursor: pointer;
    border-bottom: 1px solid #ddd;
    position: relative;
}
tr:first-child th:first-child {
    border-top-left-radius: 10px;
}
tr:first-child th:last-child {
    border-top-right-radius: 10px;
}
tr:last-child th:first-child {
    border-bottom-left-radius: 10px;
}
tr:last-child th:last-child {
    border-bottom-right-radius: 10px;
}

.newleadsbg{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 100px;
    background: #26A6DD;
    color: #fff;
    padding: 5px;
    border-radius: 6px;
}
.contactedbg{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 100px;
    background: #949393;
    color: #fff;
    padding: 5px;
    border-radius: 6px;
}
.loggeninbg{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 100px;
    background: #EA9A61;
    color: #fff;
    padding: 5px;
    border-radius: 6px;
}
.disbursedbg{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 150px;
    background: #7465DF;
    color: #fff;
    padding-left: 10px;
    border-radius: 6px;
}
.sanctionedbg{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 100px;
    background: #32C498;
    color: #fff;
    padding: 5px;
    border-radius: 6px;
}
.lostleadbg{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 100px;
    background: #FF6868;
    color: #fff;
    padding: 5px;
    border-radius: 6px;
}

.otherbg{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 100px;
    background: #c5c3d6;
    color: #fff;
    padding: 5px;
    border-radius: 6px;
}
.viewleadcolor{
    color: #473391;
}
.breakuplistflex{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0px;
    border-bottom: 1px solid #ddd;
}
.headingbreak{
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 10px;
}
.subheadingbreak{
    font-size: 10px;
}
.breakshowdetails{
    display: flex;
    justify-content: space-between;
   
}
.breakleftflot{
    width: 48%;
}
.breakrightflot{
    width: 48%;
    padding-left: 20px;
    border-left: 1px solid #ddd;
}
.mainheadingpayout{
    font-size: 14px;
    font-weight: 600;
    margin: 10px 0px;
}
.breakamountshow{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}
.amountdetailsname{
    font-size: 12px;
}
.amountdetailsprice{
    font-size: 12px;
    font-weight: 600;
}
.breaktabs{
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 10px 0px;
}
.breaktabs button{
    margin-right:10px;
    border-radius: 10px;
    border: 0;
    height: 38px;
    cursor: pointer;
    padding: 5px 25px;
    transition: .1s;
    background: #6E55B8;
    color: #fff;
}
.breaktabs button.active{
    background-color: #1AC18F;
    color: white;
}
.schemedate{
    display: flex;
    align-items: center;
}
.schememonth{
    font-size: 16px;
    font-weight: 600;
    margin: 10px 0px;
}
.tablesticky{
    position: sticky;
    top: 0;
    /* background:#6E55B8; */
}
.tablesticky tr:last-child th:first-child {
    border-bottom-left-radius: 0px;
}
.tablesticky tr:last-child th:last-child {
    border-bottom-right-radius: 0px;
}
.reassignbtn{
    width: 100%;
    max-width: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #6E55B8;
    margin-top: 20px;
    color: #fff;
    padding: 10px 30px;
    height: 42px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
}
.schemeshowtable{
    overflow: hidden;
    overflow-y: auto;
    height: 100%;
    max-height: 250px;
    padding: 0px 20px;
    border: 1px solid #ddd;
    border-radius: 10px;
    margin-top: 20px;
}
.showhistory{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.checknowbtn{
    color: #473391;
    font-size: 12px;
    cursor: pointer;
    font-weight: 600;
}
/* -----------------------------------New Lead Form----------------------------------------------- */
.leadtype{
 display: flex;
 align-items: center;
 margin-bottom: 20px;
}
.newleadbtnflex{
    display: flex;
    align-items: center;
    justify-content: center;
}
.submitbtnnewlead{
    width: 100%;
    max-width: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #6E55B8;
    margin-top: 20px;
    color: #fff;
    padding: 10px 30px;
    height: 42px;
    border: none ;
    border-radius: 30px;
    cursor: pointer;
}
.newleadcreateflexbtn{
    display: flex;
    align-items: center;
}
.newleadtabscreate {
    height: 42px;
    border-radius: 8px;
    margin: 0px 20px 20px 0px ;
  }
  .newleadtabscreate input[type="radio"] {
    display: none;
  }
  .newleadtabscreate label {
    cursor: pointer;
    padding: 0 10px;
  }
  .newleadtabscreate input[type="radio"] + label {
    border: 1px solid #ddd;
    border-radius: 30px;
    color: #2C2929;
    font-size: 14px;
    width: 100%;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .newleadtabscreate input[type="radio"]:checked + label {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #66499D;
    width: 100%;
    opacity: 1;
    border-radius: 30px;
    height: 42px;
    color: #66499D;
  }
  .csvfileupload {
    position: relative;
    width: 100%;
    height: 150px;
    background: #F4EFFF80;
    border: 3px dotted #ccc;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }
  .csvfileupload input[type='file'] {
    display: none;
  }
  .csvfileupload .file-label {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .file-label span {
    /* font-size: 36px; */
    margin-bottom: 10px;
    color: #9867FC;
  } 
  .file-label p {
    margin:0px !important;
    font-size: 12px;
    color: #666;
  }
  .leaddownload{
    span{
        color: #9867FC;
        cursor: pointer;
    }
  }
  .newleadthankyou{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 50px;
  }
  .assigncolor{
    color: #6E55B8;
  }
  .reassigntablehieght{
    max-height: 500px;
    overflow: hidden;
    overflow-y: auto;
  }
  .tablesticky{
    position: sticky;
    top: 0;
  }
/* ---------------------------------------------------------------------------------------- */
.usercontainer {
    width: 83%;
}
.detailstop{
    padding:30px;
}
.topcontainer {
    display: flex;
    align-items: center;
    border-radius: 20px;
    padding:20px;
    background-color: white;
}
.user_info {
    display: flex;
    align-items: center;
    width: 40%;
    border-right: 1px solid rgb(237, 232, 232);
}
.user_info div {
    padding: 0 5px;
}
.user_info div:first-child {
    cursor: pointer;
}
.nam_mob_mail {
    font-size: 12px;
}

.nam_mob_mail div:first-child {
    font-size: 18px;
    font-weight: 500;
}

.nam_mob_mail div img {
    padding-right: 4px;
}

.nam_mob_mail div {
    display: flex;
    padding: 1px 0;
    align-items: center;
}

.loan_id_cont {
    width: 32%;
    border-right: 1px solid rgb(210, 210, 210);
}

.loan_id_cont div button {
    background-color: rgba(59, 173, 255, 1);
    border: 0;
    cursor: pointer;
    border-radius: 5px;
    font-size: 11px;
    color: white;
    padding: 5px 10px;
}
.loan_id_cont div {
    padding: 3px 0px 0 20px;
    font-size: 12px;
}
.move_login {
    display: flex;
    gap: 10px;
}


/* ----------------------------------------------- */
.bankofferCONTPC{
    display: flex;
    gap: 30px;
}
.bankoffermainCONT{
    /* background: none; */
    overflow-y: auto;
    height: 76vh;
}
.lead_bodycontainer {
    display: flex;
    width: 100%;
    gap: 20px;
    margin-top: 20px;

}
.leftcontainer{
    width: 70%;
}
.lefttopcontainer {
    display: flex;
    gap: 20px;
    background-color: white;
    border-radius: 15px;
    padding: 10px 20px;
    overflow: hidden;
    overflow-x: auto;
    scrollbar-width: none;
    a{
        color: #000;
        width: 100%;
    }
}
.checkoffer{
    display: flex;
    align-items: center;
}
.deletedisbursedid{
    margin-left: 8px;
    cursor: pointer;
    color: red;
    font-size: 16px;
    position: absolute;  
    right: 10px;
    top: 20%;         
  }
.checkofferheading{
    font-size: 13px !important;
    font-weight: 600;
}
.sortbyfiltter{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    border: 1px solid #ddd;
    padding: 5px 20px;
    font-size: 14px;
    background: #fff;
    height: 42px;
    border-radius: 10px;
    cursor: pointer;
    position: relative;
}
.showpcfilter{
    display: none;
    position: absolute;
    background: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    padding: 20px;
    z-index: 10;
    width: 300px;
    max-height: 400px;
    overflow-y: auto;
    right: 0;
    top: 100%;
}
.sortbyfiltter:hover .showpcfilter {
    display: block;
}
.sortbyfiltter:hover svg {
    transform: rotate(180deg);
    transition: transform 0.3s ease;
  }
  .sortfilltercard
    li{
        margin-top: 20px;
        font-size: 14px;
    }
.sortlisting{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.lefttopcontainer_inner_left {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    width: 100%;
    min-width: 380px;
    border-radius: 20px;
    background-color: #fff;
    border: 1px solid #ddd;
    padding: 5px 0px;
}
.cibiltryagain{
    display: flex;
    font-size: 14px;
    font-weight: 500;
    color: #473391;
    text-decoration: underline;
    align-items: center;
    cursor: pointer;
}
.cibilscorenewscore{
    text-align: center;
    margin-left: 50px;
}
.cibilfetchscore{
    color: #473391;
    font-size: 14px;
    font-weight: 600;
}
.lefttopcontainer_inner_left div {
    /* font-size: 10px; */
    padding: 5px 10px;
}
.lefttopcontainer_inner_right {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    width: 100%;
    min-width: 380px;
    border-radius: 10px;
    background-color: rgba(246, 234, 182, 1);
}

.lefttopcontainer_inner_right div {
    font-size: 10px;
    margin: 3px 10px;
}
.leftbottomcontainer {
    border-radius: 20px;
    background: white;
    margin: 20px 0;
}

.leftbottomcontainer1 {
    display: flex;
    border-bottom: 1px solid rgb(221, 215, 215);
    padding: 10px 0px;
    align-items: center;
    justify-content: space-between;
}
.updatestatusflex{
    display: flex;
    align-items: center;
}
.updatestatusflex img{
    cursor: pointer;
    margin: 0px 10px;
}
.updatestatusbtn{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1px 20px;
    font-size: 11px;
    border: 1px solid #66499D;
    cursor: pointer;
    height: 38px;
    border-radius: 30px;

}
.leftbottomcontainer1 div button {
    margin: 2px 10px;
    border-radius: 30px;
    font-size: 12px;
    font-weight: 500;
    border: 0;
    height: 30px;
    cursor: pointer;
    padding: 5px 25px;
    transition: .1s;
    background: #F0EFF5;
}
.leftbottomcontainer1 div button.active {
    background-color: #9578E0;
    color: white;
}

.leftbottomcontainer1 div button:hover {
    background-color: #9578E0;
    color: white;
}
.editbtn{
    display: flex;
    align-items: center;
    margin: 2px 10px;
    border-radius: 30px;
    border: 1px solid #66499D;
    height: 30px;
    cursor: pointer;
    color: #6E55B8;
    font-size: 20px;
    padding: 5px 15px;
    background: #fff;  
}
.leftbottomcontainer2 {
    border-bottom: 1px solid rgb(211, 211, 211);
    display: flex;
}
.leftbottomcontainer2:last-child {
    border-bottom: none;
}
.leftbottomcontainer3 {
    display: flex;
}

.innerleftcont1_left {
    width: 50%;
    border-right: 1px solid rgb(211, 211, 211);
    padding: 28px 30px;
}
.innerleftcont1_right {
    width: 50%;
    padding: 28px 30px;

}
.leftcontainerheight{
    height: 100%;
    min-height: 420px;
}
.loan_review_heading {
    display: flex;
    padding: 6px 10px;
    justify-content: space-between;
}
.loan_review_heading div:first-child {
    font-weight: 500;
    font-size: 16px;
}
.loan_review_heading div:last-child {
    color: #000;
    font-size: 16px;
    cursor: pointer;
}

.loan_review {
    display: flex;
    padding: 6px 10px;
    font-size: 12px;
    justify-content: space-between;
}
.loan_review div:first-child {
    font-size: 12px;
    color: #878484;
}

.loan_review div:last-child {
    font-weight: 500;
    font-size: 12px;
}
.innerleftcont_left {
    width: 50%;
    border-right: 1px solid rgb(211, 211, 211);
    padding: 28px 30px;
}

.innerleftcont_right {
    width: 50%;
    padding: 28px 30px;
}
.rightcontainer {
    width: 30%;
}
.righttopcontainer {
    position: relative;
    width: 100%;
    height: 100%;
    max-height: 350px;
    border-radius: 12px;
    background-color: white;
}

.folloupheading{
    padding: 10px;
    font-size: 14px;
    font-weight: 500;
    border-bottom: 1px solid rgb(211, 211, 211);
    margin-bottom: 10px;
}
.followupddone{
    display: flex;
    align-items: center;
    gap: 10px;
}
.followupstatus{
    font-size: 11px;
    font-weight: 500;
    color: #473391;
    margin-bottom: 10px;
    cursor: pointer;
}
.followupflex{
    display: flex;
    /* align-items: center; */
    font-size: 11px;
    margin-bottom: 5px;
    img{
        margin-right: 5px;
    }
}
.add_notes_btn {
    text-align: right;
    cursor: pointer;
    right: 0px;
    position: absolute;
    bottom: 0px;
}
/* .followupcard{
    height: 100%;
    max-height: 235px;
    overflow: hidden;
    overflow-y: auto;
} */
.notes_contain {
    height: 100%;
    max-height: 235px;
    overflow: hidden;
    padding: 0px 20px;
    overflow-y: auto;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
}
.folloupdetailsnew{
    width: 60%;
    border-right: 1px solid rgb(211, 211, 211);  
    padding-right: 20px;
}
.nextfollowup{
    font-size: 12px;
    font-weight: 500;
}
.notfollowup{
    margin-top: 20px;
    padding: 20px 0px;
}
.folloupbox{
    width: 100%;
    background: #ECEEFA;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 10px;
}
.nofollowup{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 100px;
}
/* .notes_contain img {
    margin: 12px 0;
} */
.rightbottomcontainer {
    background-color: white;
    margin-top: 20px;
    border-radius: 10px;
    padding: 10px;
}
.other_info {
    border-bottom: 1px solid rgb(188, 182, 182);
    font-size: 14px;
    padding: 4px 0;
    font-weight: 500;
    margin-bottom: 5px;
}
.flexrightdiv {
    display: flex;
    padding: 10px 0;
    font-size: 12px;
    justify-content: space-between;
}
.otherinfocolor{
    color: #878484;
}
@media screen and (max-width: 968px) {
    .bankofferCONTPC{
        display: none;
        
    }
}
/* ----------------Documents-------------------------- */
.documentcard{
    padding: 20px;
}
.documenttabs{
    display: flex;
    align-items: center;
    justify-content:space-between;
    margin-bottom: 20px;
}
.doctabswidth{
    width: 100%;
    max-width: 70%;
}
.partnername{
    font-size: 10px;
    color: #473391;
    margin-bottom: 10px;
}
.coapplicantname{
    font-size: 10px;
    margin-bottom: 10px;
}
.commontabs{
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  .commontabsItem {
    min-width: 200px;
    margin: 0;
    padding: 10px 20px;
    height: 38px;
    cursor: pointer;
    border-bottom: none;
    display: flex;
    align-items: center;
    justify-content: center;
    border:1px solid #ddd;
    border-radius: 10px;
    position: relative;
  }
.commontabsItem.active{
    color:#473391;
    border:1px solid #473391;
    border-radius: 10px;
  }

.doclistbtn{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 38px;
    padding: 10px 30px;
    font-size: 12px;
    cursor: pointer;
    background: #6E55B8;
    color: #fff;
    border: none;
    border-radius: 10px;
    margin-top: 20px;
}
.addcoapplicantbtn{
    display: flex;
    align-items: center;
    color: #6E55B8;
    cursor: pointer;
    span{
        font-size: 22px;
        font-weight: 600;
        margin-right: 10px;
    }
}
.coapplicantcheckbox{
    display: flex;
    align-items: center;
    margin: 20px 0px 43px 0px;
    input{
        margin-right: 10px;
    }
}
.doccardlfex{
    display: flex;
    gap: 40px;
    margin-bottom: 20px;
}
.doccardleft{
    width: 50%;
}
.doccardright{
    width: 50%;
}
.documentlist{
    display: flex;
    flex-direction: column;
    border: 1px solid #ddd;
    /* padding: 10px 0px; */
    border-radius: 10px;
}
.doclistshow{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    
    cursor: pointer;
}
.docupload{
    border-top: 1px solid #ddd;
    padding: 10px;
    display: none; 
    height: 100%;
}
.docshow{
    display: block !important;
    max-height: 200px;
    /* min-height: 200px; */
    overflow: hidden;
    overflow-y: auto;
}
.doclistshow.active + .docupload {
    display: block;
}
.rotate-icon {
    transition: transform 0.3s ease;
  }
.rotate-icon.open {
    transform: rotate(180deg);
}


.doclistflex{
    display: flex;
    align-items: center;
    img{
        margin-right: 10px;
    }
}

.docuploadcheck{
    display: flex;
    align-items: center;
    margin:10px 0px;
    cursor: pointer;
    input{
    margin-right: 10px;
    }
    label{
    cursor: pointer;
    }
}
.dcoheading{
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 20px;
}
.docimagecard{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
}
.docimagecard li{
    width: 100px;
    height: auto;
    min-height: 120px;
    max-height: 120px;
    padding: 5px;
    border: 1px solid #ddd;
    border-radius: 10px;
    cursor: pointer;
    input{
        margin: 10px;
    }
}
.docbtnall{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
}
.docremoebtn{
    border: 1px solid #66499D;
    background: #fff;
    height: 42px;
    cursor: pointer;
    border-radius: 30px;
    padding: 10px 30px;
    width: 100%;
    max-width: 150px;
}
.docreuploadbtn{
    border: none;
    background: #66499D;
    height: 42px;
    color: #fff;
    cursor: pointer;
    border-radius: 30px;
    padding: 10px 30px;
    width: 100%;
    max-width: 150px;
}
.docuploadlistshow{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0;
}
.uploadButton{
    color: #473391;
    cursor: pointer;
}


/* ----------------History-------------------------- */
.histry_main_cont {
    height: 390px;
}
.histry_cont {
    margin: 10px 30px;
}
.histry_cont div:first-child {
    font-size: 14px;
    font-weight: 500;
}
.histry_cont div:nth-child(2) {
    font-size: 11px;
    font-weight: 400;
    margin: 5px 0;
}
.grey_line {
    border-color: rgb(241, 243, 243);
}
/* -----------------------------------Edit Lead container--------------------------- */
.editleadnew{
    display: flex;
    gap: 40px;
    height: 100%;
    min-height: 650px;
    background: #fff;
    margin-top: 20px;
    padding: 30px 20px ;
    border-radius: 20px;
}
.EditLeadcont {
    border-radius: 10px;
    width: 96%;
    margin: 0 auto;
    display: flex;
    height: 470px;
    background-color: white;
}
.lefteditleadcont {
    width: 20%;
    background: rgba(249, 247, 255, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
}
.righteditleadcont{
    position: relative;
    width: 75%;
}
.editnextbtn{
    position: absolute;
    bottom: 0;
    right: 0;
    background: #66499D;
    color: #fff;
    height: 42px;
    padding: 10px 30px;
    border: none;
    border-radius: 30px;
    cursor: pointer;
}

.editnextbtn1{
    background: #66499D;
    color: #fff;
    height: 42px;
    padding: 10px 30px;
    border: none;
    border-radius: 30px;
    cursor: pointer;
}

.saveandexit{
    display: flex;
    position: absolute;
    bottom: 0;
    right: 0;
    gap: 10px;
}

.editlead_Heading {
    margin: 20px 20px 0 20px;
    font-size: 20px;
    font-weight: 600;
}
.editlead_next {
    position: absolute;
    right: 4%;
    bottom: 17%;
}
.editlead_next button {
    background-color: rgba(102, 73, 157, 1);
    color: white;
    height: 32px;
    border: 0;
    cursor: pointer;
    border-radius: 21px;
    width: 95px;
}
.customerflex{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}
.customerheading{
margin-bottom: 0px;
}
.coapplicantadd{
    display: flex;
    align-items: center;
    color: #66499D;
    cursor: pointer;
    font-weight: 600;
    span{
        font-size: 24px;
        margin-right: 10px;
    }
}
/* -----------------------Multi Step Bar------------------------- */
.step-wizard {
    height: 100%;
    width: 100%;
    display: flex;
    /* justify-content: center; */
    /* align-items: center; */
    
}
.step-wizard-list {
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0);
    color: #333;
    list-style: none;
    border-radius: 10px;
    padding: 20px;
    position: relative;
    z-index: 10;
}
.step-wizard-item {
    padding: 24px 0px;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; 
    display: flex;
    flex-direction: column;
    text-align: right;
    position: relative;
    cursor: pointer;
}
.step-wizard-item +.step-wizard-item:after {
    content: "";
    position: absolute;
    left: 50%;
    bottom: 45px;
    background: #ddd;
    height: 100%;
    width:  2px;
    transform: translateX(-50%);
    z-index: -10;
}
.progress-count {
    height: 24px;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-weight: 600;
    margin: 0 auto;
    position: relative;
    z-index: 10;
    color: transparent;
}
.progress-count:after {
    content: "";
    height: 24px;
    width: 24px;
    background: #66499D;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    z-index: -10;
}
.progress-count:before {
    content: "";
    height: 4px;
    width: 10px;
    border-left: 2px solid #fff;
    border-bottom: 2px solid #fff;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -60%) rotate(-45deg);
    transform-origin: center center;
}
.progress_label {
    font-size: 14px;
    font-weight: 600;
    margin-top: 0px;
}
.current-item .progress-count::before,
.current-item ~.step-wizard-item .progress-count:before {
    display: none;
}
.current-item ~.step-wizard-item .progress-count:after {
    height: 10px;
    width: 10px;
    background-color: #66499D;
}
.current-item .progress-count:after {
    background: rgba(237, 231, 252, 1);
    border: 2px solid #66499D;
}
.current-item .progress-count {
    color: #66499D;
}

.Editlead_flowlis li{
margin: 50px 0;
list-style: none;
color: rgba(0, 0, 0, 1);
cursor: pointer;
}
.propertidentifyd{
    margin: 10px 20px;
}
.propertidentifyd div{
    margin: 10px 0px;
}
.propertidentifyd input,label{
    margin: 0 8px 0 0;
}
.getrecomndbtn{
    background-color:     #9578E0;
    border-radius: 10px;
    color: #fff;
    border: 0;
    padding: 7px 10px;
}

.historymenucard{
    max-height: 400px;
    overflow: hidden;
    overflow-y: auto;
    padding: 20px 0px;
}
.statusborder{
    border-bottom: 1px solid lightgrey;
    width: 100%;
    padding-bottom: 10px;
}
.historydetails{
    font-size: 14px;
    font-weight: 600;
}
.statusdetails{
    font-size: 12px;
}
.menu-tab-left-panel-new {
    width: 100%;
    background: #fff;
    position: relative;
    ul {
        li {
            position: relative;
          
            p {
                display: flex;
                color: rgba(0, 0, 0, 0.5);
                width: 100%;
                padding-bottom: 10px;
                margin-bottom: 10px;
                /* display: inline-block; */
                .img-type {
                    background: #fff;
                    border: 2px solid rgba(0, 0, 0, 0.37);
                    height: 16px;
                    width: 16px;
                    margin-top: 2px;
                    margin-left: 15px;
                    margin-right: 15px;
                    vertical-align: top;
                    display: inline-block;
                    position: relative;
                    border-radius: 50%;
                    z-index: 2;
                }
                &.completed {
                    color: #000;
                    &:after {
                        content: "";
                        border-left: 2px dotted #ddd;
                        left: 22px;
                        position: absolute;
                        top: 18px;
                        height: 100%;
                    }
                    .img-type {
                        background: #9C77E3;
                        border: 1px solid #9C77E3;
                        position: relative;
                        &:after {
                            content: "\e927";
                            position: absolute;
                            z-index: 2;
                            left: 0px;
                            top: -1px;
                            font-family: dc !important;
                            color: #fff;
                            font-size: 12px;
                            font-weight: 600;
                        }
                    }
                }
                &.active {
                    color: #000;
                    .img-type {
                        background: #9C77E3;
                        border: 1px solid #9C77E3 !important;
                        position: relative;
                    }
                }    
            }

            .completed {
                &:after {
                    content: "";
                    border-left: 1px solid #9C77E3;
                    left: -7px;
                    position: absolute;
                    top: 18px;
                    height: 100%;
                }
                .img-type {
                    background: #9C77E3;
                    border: 1px solid #9C77E3;
                    position: relative;
                    &:after {
                        content: "\e927";
                        position: absolute;
                        z-index: 2;
                        left: 0px;
                        top: 1px;
                        font-family: dc !important;
                        color: #fff;
                        font-size: 12px;
                        font-weight: 600;
                    }
                }
                .dropdown-toggle {
                    color: #000;
                }
            }
            
        }
    }
}

/* -----------------------My Teams------------------------- */

.myteamsflex{
    display: flex;   
}
.schedlefollowdetails{
    margin: 20px 0px;
}
.addteammember{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 30px 0px;
}

/* ----------------------- Cibil Score ------------------------- */


.mobileedit{
    color: #473391;
    text-align: right;
    cursor: pointer;
    margin-top: -16px;
}
.concentlink{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.concentsms{
    padding: 10px 30px;
    background: #EEECFF;
    font-size: 10px;
    border-radius: 10px;
    cursor: pointer;
}
.additionalflex{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.additionldetails{
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}
.additonaltype{
    margin-bottom: 20px;
}
.cibilscoreflex{
    display: flex;
    flex-direction: column;
    /* gap: 20px;
    align-items: center;
    justify-content: center; */
    border: 1px solid#ddd;
    padding: 10px 20px;
    border-radius: 20px;
    min-height: 225px;
}
.cibilscorecard{
    border: 1px solid #ddd;
    border-radius: 20px;
    /* padding-top: 20px ; */
}
.cibilscorenew{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    background: #F2F1FF;
    border-radius: 20px 20px 0px 0px;
    padding: 20px;
}
.socreshow{
    font-size: 16px;
    font-weight: 500;
}
.cibilnamecard{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 20px;
}
.cibilname{
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 5px;
}
.viewreportcibil{
    a{
        color: #6147FF;
        cursor: pointer;
    }

}
.creditviewreport{
    font-size: 11px;
    color: #66499D;
    font-weight: 600;
    cursor: pointer;
}
.cibilpoweredby{
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 10px;
    span{
        color: #6E55B8;
        font-size: 11px;
        
    }
}
.powerby{
    font-size: 11px;
}

.scorebox{
    margin-left: -16px;
    margin-right: -16px;
    background: #fff;
    padding: 16px;
}
.scorecard{
    background: #fff;
    border-radius: 10px;
    margin-bottom: 20px;
}
.scoreflex{
    padding: 20px;
}
.cibilscorebox{
    position: relative;
}
.cibilscore{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}
.cibilscoreheading{
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 20px;
}
.scoreimpact{
    font-size: 13px;
    color: gray;
    margin-bottom: 10px;
}
.cibilupdated{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid lightgray;
    span{
        font-size: 14px;
        font-weight: 600;
        color: gray;
    }
}
.cibilhighscore{
    display: flex;
    align-items: center;
    border-radius: 8px;
    margin-right: 10px;
}
.cibilicon{
    display: flex;
    align-items: center;
}
.cibilhistory{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}
.cibilstatus{
    color:#000;
    font-size: 13px;
    font-weight: 600;
}
.cibilsubstatus{
    font-size: 12px;
    color: gray;
}
.cibilcolorper{
    font-size: 14px;
    color: #6E55B8;
    font-weight: 600;
    text-align:end;
}
.viewreport{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0px;
    cursor: 'pointer';
}
.reportshow{
    font-size: 14px;
    margin-left: 10px;
}
.checkloan{
    background: #fff;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;
}
.offercard{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.offerheading{
    font-size: 16px;
    font-weight: 600;
}
.offerpara{
    font-size: 12px;
    color: #000;
}
.offerbtn {
    height: 42px;
    background: #6E55B8;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    color: #fff;
    padding: 0px 30px;
}
.offerhidemobile{
    display: none;
}
.offerhidepc{
    display: initial;
}
.goodofferhidemobile{
    display: none;
}
.nextbtn {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #6E55B8;
    color: white;
    border: none;
    height: 42px;
    font-size: 14px;
    min-width: 170px;
    cursor: pointer;
    border-radius: 8px;
    position: relative;
  }
.nextbtn img{
    margin-left: 10px;
    width: 20px;
    height: 9px;
}
.styles_scoreValue__2dBgK{
    display: none;
}


@media(min-width:900px){
    .box {
        padding: 50px 0;
        height: calc(100vh - 80px);
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
    }
    .pcboxwithoutheight{
        height: 100vh;
        min-height: 100%;
    }
    .pcfixes{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .pcbox{
        background: #fff;
        padding: 20px;
        border-radius: 20px;
        width: 100%;
        max-width: 1100px;
    }
    .pccard{
        display: flex;
        align-items: center;
        justify-content: space-around;
    }
    .homeloancard{
        min-height: 100%; 
        width: 50%;
    }
    .backclick{
        justify-content: flex-start;
    }
    .btnflex{
        justify-content: flex-end;
    }
    .priceinput{
        width: 100%;
    }
    .sendotp{
        margin-bottom: 10px;
    }
    .offerheading{
        span{
            color: #6E55B8;
        }
    }
    .loanofferwidth{
        width: 60%;
    }
    .offerpccard{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .offerhidemobile{
        display: initial;
        width: 100%;
        max-width: 1100px;
    }
    .goodofferhidemobile{
        display: initial;
        width: 25%;
    }
    .viewreport{
        justify-content: flex-end;
        cursor: pointer;
    }
    .viewreportsflex{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .cibilscoreheading{
        width: 100%;
        margin-bottom: 0px;
    }
    .checkloan{
        margin-top: 20px;
        border-radius: 20px;
        width: 100%;
        max-width: 1100px;
    }
    .pcofferflex{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .offerhidepc{
        display: none;
    }
    .cibilpoweredby{
        justify-content: flex-start;
    }
    .powerby{
        margin-left: 10px;
    }
    .cibilupdated{
        border-bottom: none;
        margin-bottom: 20px;
    }
    .goodofferheading{
        font-size: 22px;
    }
    .goodoffersubheading{
        font-size: 13px;
    }
    .scorebox{
        border-left: 1px solid lightgray;
        width: 45%;
        padding: 0px 16px;
    }
    .homemainimgmobile{
       display: none;
    }
    .homemainimgpc{
        display: initial;
        img{
            width: 400px;
            height:250px;
        }
    }
}


/* ----------------------- Carousel ------------------------- */

.bankcarouselcard{
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 20px;
    width: 100%;
    margin-bottom: 25px;
    height: 180px;
}
.bankcarousellist{
    padding: 20px 20px 10px 20px;
    border-bottom: 1px solid #ddd;
}
.loanbankflexhed{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
}
.newbankcard{
    border: 1px solid #ddd;
    border-radius: 20px;
    border-bottom: none;
    height:220px;
    box-sizing: border-box;
}
.newloanofferhead{
    font-size: 16px;
    font-weight: 500;
}
.offerrecal{
    font-size: 12px;
    color: #66499D;
    font-weight: 500;
    cursor: pointer;
}
.processingfees{
    display: flex;
    /* align-items: center; */
    justify-content: space-between;
    width: 100%;
}
.bankofferfixed{
    position: fixed;
    bottom: 20px;
    left: 40%;
}
.blistankname{
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    span{
        margin-left: 10px;
    }
    /* img{
        width: 150px !important;
    } */
}
.priceinamount{
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 5px;
}
.priceinmonth{
    font-size: 10px;
}
.bankpricelist{
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: start;
}
.bankonwordsnew{
    font-size: 9px;
    text-align: right;
    /* margin-top: 5px; */
}
.bankonwords{
    font-size: 10px;
    /* text-align: right; */
    margin-top: 5px;
}
.bankprocessing{
    display: flex;
    align-items: center;
    text-align: start;
    font-size: 10px;
    padding: 16px 20px;
    span{
        display: flex;
        flex-direction: column;
        width: 5px;
        height: 5px;
        margin-right: 5px;
        background: #000;
        border-radius: 50%;
    }
}
.carousel .control-dots .dot{
    background: #000 !important;
}
.styles_wrapper__3KXDn{
    height: 150px !important;
}
.styles_rangeSvg__1TDxQ{
    width: 180px !important;
}
.carousel .control-dots{
bottom: -10px !important;
}

.carousel.carousel-slider .control-arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    opacity: 1;
    top: 30% !important;
    color: #fff;
    /* background: rgba(0, 0, 0, 0.4) !important; */
    font-size: 26px;
    bottom: 0;
    margin-top: 0;
    padding: 5px;
}
.carousel .control-arrow, .carousel.carousel-slider .control-arrow {
    opacity: 1 !important;
}
.carousel.carousel-slider .control-arrow:hover{
    background:#9578E0 !important;
    color: #fff !important;
}
.carousel .control-arrow:before, 
.carousel.carousel-slider .control-arrow:before {
    content: '>';
    font-size: 20px; 
    color: #fff;
}
.carousel .control-next.control-arrow:before {
    content: '>' !important;
    display: inline-block;
    font-size: 20px;
    color: #000;
    border-left: none !important;
}

.carousel .control-prev.control-arrow:before {
    content: '<' !important;
    display: inline-block;
    font-size: 20px;
    color: #000;
    border-right: none !important;
}

/* ----------------------- Bnak Offer ------------------------- */



.loanofferdetailsnew{
display: flex;
gap: 20px;
justify-content:space-between;
padding: 19px;
border-bottom: 1px solid #ddd;
min-height: 280px;
}
.loanofferdetailsnewleft{
    width: 49%;
}
.loanofferdetailsnewright{
    width: 49%;
}
.bancoffercard{
    background: #fff;
    padding: 20px;
    border-radius: 20px;
}
.bankofferselect{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0px;
}
.bankselectbox{
    display: flex;
    align-items: center;
    gap: 50px;
    width: 95%;
}
.bankofferrecalculate{
    font-size: 14px;
    font-weight: 600;
    color: #473391;
    cursor: pointer;
}
.banktipscard{
    display: flex;
    align-items: center;
    max-width: 500px;
    gap: 20px;
    font-size: 12px;
    background-color: #FEFFE2;
    border: 1px solid #FFD517;
    border-radius: 15px;
    padding: 10px;
    span{
        font-weight: 600;
    }
}
.newloancomon{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
}
.loantype{
    font-size: 18px;
    font-weight: 600;
    margin: 30px 0px;
}
.bankoffercard{
    position: relative;
    background: #fff;
    border-radius: 16px;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    margin-top: 10px;
}
.offerrecommended{
    background: linear-gradient(269.79deg, #F9FBB9 -3.9%, #FF8D1B 124.76%);
    border-radius: 0px 16px 0px 20px;
    position: absolute;
    padding: 10px 20px;
    font-size: 10px;
    top: 0px;
    right: 0px;
}
.offertenureflex{
    display: flex;
    justify-content: end;
    margin-bottom: 20px;
}
.offertimecard{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    background: #EFF7FD;
    padding: 5px 10px;
    font-size: 11px;
    border-radius: 30px;

}
.offerselect-container {
    position: relative;
    /* display: inline-block; */
  }
  
  .offerselect-container select {
    padding-right: 30px;
  }
  
  .offerselect-container i {
    position: absolute;
    top: 50%;
    right:40px;
    transform: translateY(-50%);
  }
.offerpadding{
    padding: 0px 10px;
}
.bankofferbox{
    display: flex;
    align-items: center;
    justify-content: space-between;
  
}
.bankcheckbox{
    display: flex;
    align-items: center;
    margin-right: 10px;
}
.priceofferbox{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px; 
}
.banklogo{
    display: flex;
    align-items: center;
    img{
        object-fit: contain;
    }
    span{
        margin-left: 10px;
        font-size: 14px;
    }
}
.offerprice{
    font-size: 15px;
    font-weight: 600;
}
.bankonwords{
    font-size: 10px;
    color: gray;
}
.bankonwordspc{
    font-size: 12px;
    color: gray;
}

.offergstcard {
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 50px;
    background: #EFF7FD;
    padding: 5px 10px;
    border-radius: 30px;
    margin-left: 16px;
    width: 45%;
  }
  
  .offershowfees {
    display: none;
    position: absolute;
    background: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    padding: 10px;
    z-index: 10;
    width: 100%;
    max-height: 300px;
    overflow-y: auto;
    left: 0;
    top: 100%; 
  }
  
  .offergstcard:hover .offershowfees {
    display: block;
  }
  
  .offergstcard:hover .ic-keyboard_arrow_down {
    transform: rotate(180deg);
    transition: transform 0.3s ease-in-out;
  }
  .offerfeesflex{
    display: flex;
    /* justify-content: end; */
    margin-right: 20px;
    gap: 20px;
    width: 100%;
  }
  .offerfast{
    display: flex;
    gap: 10px;
    align-items: center;
    min-width: 32%;
    max-width: 90%;
    background: #F4F3DD;
    padding: 10px 20px;
    font-size: 11px;
    border-radius: 30px;
  }
  .offerdatesched{
    text-align: center;
  }
  .offerflotleft{
    width: 23%;
  }
.offercheckhere{
    background: #E4DDFD;
    padding:20px;
    border-radius: 20px;
    margin-bottom: 20px;
}
.offercreditflex{
    display: flex;
    align-items: center;
    gap: 10px;
}
.offercreditheading{
    font-size: 13px;
    font-weight: 600;
}
.clickofferbtn{
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FF8060;
    color: #fff;
    padding: 10px 20px;
    border-radius: 10px;
    cursor: pointer;
    border: none;
    height: 32px;
    margin-top: 20px;
}
.offercallcard{
    background: #EFF7FD;
    border: 1px solid #E7E7E7;
    border-radius: 16px;
}
.offercallflex{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    gap: 10px;
    border-bottom: 1px solid #ddd;
    margin-bottom: 20px;
}
.offerneed{
    font-size: 15px;
    font-weight: 600;
}
.offersubneed{
    font-size: 12px;
}
.offerbtncard{
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 20px;
}
.offercallbtn{
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 10px;
    padding: 10px;
    height: 32px;
    border: 1px solid #9578E0;
    background: #fff;
    border-radius: 10px;
    cursor: pointer;
}
.offerscheduldebtn{
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 10px;
    font-size: 10px;
    height: 32px;
    border: 1px solid #9578E0;
    background: #fff;
    border-radius: 10px;
    cursor: pointer;
}
.offergst{
    font-size: 12px;
    span{
        display: inline-block;
        height: 4px;
        width: 4px;
        background-color: black;
        border-radius: 50%;
        margin-right: 5px;
    }
}
.appylbtnborder{
    border-top:1px solid lightgray ;
    display: flex;
    align-items: center;
    justify-content: center;
}
.btnflex{
    display: flex;
    align-items: center;
    justify-content: center;
}
.applybtn{
    width: 220px;
    height: 42px;
    color: #fff;
    background: #6E55B8;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    cursor: pointer;
    margin: 20px 0px;
}
.mobileonwordshide{
    display: none;
}
.offergstpc{
    display: none;
}

.thankyou{
    padding: 40px 0px;
}
.mainheading{
    font-size: 22px;
    font-weight: 500;
}
.imagecenter{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
}
.imagethankyou{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 100px 0px;
}
.textcenter{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.mobilehide{
    display: none;
}
.managercont{
    width: 100%;
}
.tenureyears{
        margin-top: 20px;
        font-size: 12px;
}
.bankselected{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    background: #473391;
    border-radius: 30px;
    height: 60px;
    padding: 10px 20px;
    color: #fff;
    min-width: 550px !important;
    margin-top: 20px;
}
.bankselected input {
    width: 24px;
    height: 24px;
    background: #2ACB74 !important;
}
.selectedbankshare{
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    border: none;
    height: 38px;
    border-radius: 30px;
    padding: 10px 20px;
    width: 100%;
    /* max-width: 126px; */
    cursor: pointer;
}
.offerlistingfees {
    width: 100%;
    border-bottom: 1px solid #ddd;
    margin-top: 10px;
    padding-top: 10px;
}
input[type="checkbox"]:checked {
    accent-color: #0E9700;
}
.bankoffertipsflex{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.bankloantips{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #FEFFE2;
    border: 1px solid #FFD517;
    height: 42px;
    font-size: 10px;
    padding: 10px 20px;
    border-radius: 14px;
}


@media(min-width:900px){
    .bankofferbox{
        background: none;
        /* padding-bottom: 20px; */
    }
    .pcoffercard{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 20px 0px 20px;
        /* margin-top: 20px; */
    }
    .backclick{
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 40px;
    }
    .bankonwordspc{
        display: none;
    }
    .offergst{
        display: none;
    }
    .mobilehide{
        display: initial;
    }
    .mobileonwordshide{
        width: 100px;
        display: initial; 
        font-size: 12px;
        margin-top: 65px;
        margin-right: 10px;
        color: gray;
        border-bottom: 1px solid lightgray;
    }
    .managercont{
        width: 30%;
    }
    .bankoffercard{
        padding-bottom: 20px;
    }
    .offerpadding{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 80%;
        padding-bottom: 10px;
        border-bottom: 1px solid lightgray;
    }
    .priceofferbox{
        width: 100%;
    }
    .applybtn{
        width: 150px;
        margin-top: 50px;
    }
    .appylbtnborder{
        border-top: none;
        border-left: 1px solid lightgray;
        padding-left: 40px;
    }
    .offergstpc{
        /* padding: 0px 0px 10px 36px; */
        font-size: 12px;
        display: block;
        width: 300px; /* Adjust based on your design */
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        span{
            display: inline-block;
            height: 6px;
            width: 6px;
            background-color: black;
            border-radius: 50%;
            margin-right: 5px;
        }
    }
    .hidefasttext{
        width: 150px; /* Adjust based on your design */
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .offerfees{
        padding: 0px 0px 10px 0px;
        font-size: 12px;
        display: block;
        /* width: 100%; */
        span{
            display: inline-block;
            height: 6px;
            width: 6px;
            background-color: black;
            border-radius: 50%;
            margin-right: 5px;
        }
    }
    .slide {
        background: #fff;
    }
    .offerfeeslist{
        display: flex;
        align-items: center;
        justify-content: space-between;
        /* max-width: 80%; */
    }
    .offerpricelist{
        display: flex;
        align-items: center;
        gap: 10px;
        width: 80%;
    }
    .banklogo{
        span{
            font-size: 14px;
            font-weight: 600;
        }
    }
    .offerprice{
        font-size: 15px;
    }
}


/* --------------------------------------- query Details   ------------------------------ */


.querycard{
     background: #fff;
     border-radius: 10px;
     margin-top: 20px;
     padding-bottom: 20px;
}
.queryheading{
    padding: 10px;
    font-size: 14px;
    font-weight: 600;
}
.querysubheading{
    font-size: 12px;
    font-weight: 600;
    text-align: start;
}
.querybox{
    display: flex;
    align-items: center;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    padding: 20px;
    gap: 20px;
    margin-bottom: 20px;
}
.queryimgflex{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.displayflex{
    display: flex;
    align-items: center;
    gap: 10px;
}

.reuploadbtn{
    display: flex;
    align-items: center;
    justify-content: center;
    background:#F37171;
    padding: 5px;
    font-size: 10px;
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
}
.querydate{
    font-size: 10px;
}



/* --------------------------------------- Status Update   ------------------------------ */

.updatestatusdetails{
    margin-bottom: 20px;
}
.updatefileupload {
    position: relative;
    width: 100%;
    height: 42px;
    border: 2px dotted #ccc;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin: 20px 0px;  */
  }
  .updatefileupload input[type='file'] {
    display: none;
  }
  .updatefileupload .file-label {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .file-label span {
    margin-bottom: 0px;
    color: #9867FC;
  } 
  .statusdoc{
    font-size: 9px;
    margin-left: 5px !important;
  }
  .showimage{
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ddd;
    border-radius: 10px;
    width: 15%;
    height: 42px;
    padding: 10px 20px;
    cursor: pointer;
    position: relative;
  }
  .showimage .close-icon {
    position: absolute;
    top: 0px;
    right: 5px;
    font-size: 18px;
    color: #000;
    cursor: pointer;
  }
  
  .prebtnnewlead{
    width: 100%;
    max-width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    margin-top: 20px;
    color: #000;
    padding: 10px 30px;
    height: 42px;
    border: 1px solid #6E55B8 ;
    border-radius: 30px;
    cursor: pointer;
  }
  .updatestatusflex{
    display: flex;
    align-items: center;
    justify-content: end;
    margin-top: 20px;
  }
  .submitbtnnewleadstatus{
    width: 100%;
    max-width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #6E55B8;
    margin-top: 40px;
    color: #fff;
    padding: 10px 30px;
    height: 42px;
    border: none ;
    border-radius: 30px;
    cursor: pointer;
  }
  .submitbtnnewleadpartner{
    width: 100%;
    max-width: 190px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #6E55B8;
    margin-top: 20px;
    color: #fff;
    padding: 10px 30px;
    height: 42px;
    border: none ;
    border-radius: 30px;
    cursor: pointer;
  }
  

  /* ------------------------ New Login -------------------------------*/

.leftheadinglogin{
    padding-left: 70px;
}
.newloginimg{
    position: fixed;
    bottom: 0px;
    height: 70%;
    img{
        height: 100%;
    }
}
.newloginimgkyc{
    display: flex;
    justify-content: center;
    position: fixed;
    bottom: 0px;
    left: 10%;
    height: 60%;
}
.newlogincard{
    position: relative;
    background: #fff;
    border-radius: 40px;
    padding: 40px;
    width: 100%;
    height: 100%;
}
/* .policylink{
    position: absolute;
    bottom: 20px;
} */

.logincontainer {
    display: flex;
    justify-content: space-between;
    padding-right: 50px;
    min-height: 100vh;
  }
  .left-side, .right-side {
    flex: 1;
  }
  .right-side {
    display: flex;
    justify-content: center;
  }
  
  .shadow-box {
    width: 70%;
    margin: 40px 0;
    padding: 80px;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    /* flex: 1; */
    display: flex;
    flex-direction: column;
    border-radius: 20px;
  }
  .middle-content {
    margin: auto 0; 
  }
  .logindetialsnew{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 30px 0px;
  }
  .subheadinglogin{
    font-size: 16px;
  }
  .mainheadingloginnew{
    font-size: 16px;
    font-weight: 600;
  }
  
  
  /* .top-content, .middle-content, .bottom-content {
  }   */
  .bottom-content {
    margin-top: 10px;
  }

/* --------------- KYC -----------------*/

.kycskip{
    text-align: right;
    cursor: pointer;
}
.kycsubheading{
    margin-bottom: 20px;
}
.bottonfix{
    /* position: absolute; */
    width: 100%;
    bottom: 40px;
}
.flexgst{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}
.verifiedbank{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 100px;
}
.gifscreen{
    background: #EEECFF;
    height: 42px;
    display: flex;
    align-items: center;
    padding: 10px 20px;
    border-radius: 10px;
    position: absolute;
    bottom: 50px;
}

.progress-container {
    width: 100%;
    padding: 20px 0px;
  }
  .loginprogress-bar {
    width: 100%;
    background-color: #e0e0e0;
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 20px;
  }
  .loginprogress {
    height: 8px;
    background-color: #473391;
    transition: width 0.3s;
  }
  .loginsteps {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  .step {
    flex: 1;
    text-align: center;
    position: relative;
    color: #757575;
  }
  .step.active {
    color: #473391;
  }
  .step::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 100%;
    width: 100%;
    height: 2px;
    background-color: #e0e0e0;
    z-index: -1;
  }
  .step:last-child::after {
    display: none;
  }
  .step.active::after {
    background-color: #473391;
  }

  .bankverifiedflex{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 0px 40px;
  }
  .bankheadingmain{
    font-size: 18px;
    font-weight: 600;
    margin-top: 20px;
  }
  .banklaterbtn{
    background: #fff;
    height: 42px;
    padding: 10px 30px;
    border-radius: 10px;
    border: 1px solid #473391;
    color: #473391;
    cursor: pointer;
    font-family: poppins;
    margin-top: 20px;
    margin-right: 20px;
  }
  .dashbordbtn{
    background: #473391;
    height: 42px;
    padding: 10px 20px;
    border-radius: 10px;
    border: none;
    color: #fff;
    cursor: pointer;
    font-family: poppins;
    margin-top: 20px;
  }
  .kycdocumentmatch{
    text-align: left;
    p{
        margin-bottom: 5px;
    }
  }
  .hideinputborder {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 10px;
    height: 42px;
    border: 1px solid #ccc;
    border-radius: 10px;
    width: 100%;
    span{
        margin-left: 5px;
        font-size: 12px;
    }
  }
  
  .custom-input {
    width: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 40px;
    justify-content: space-between;
    margin: 0px;
  }
  
  .custom-input i,
  .custom-input span {
    margin-right: 5px;
  }
  
  .custom-file-input {
    display: none;
  }
  .maininfokyc{
    display: flex;
    flex-direction: column;
    text-align: center;
    /* align-items: center;
    justify-content: center; */
  }
  .kycinfooverflow{
    height: 100%;
    /* max-height: 310px;
    overflow: hidden;
    overflow-y: auto; */
  }
  .mainkycinput{
    margin-top: 10px;
  }
  .mobilenumbershow{
    margin: 10px 0px 20px 0px;
  }
  .mainflexbg{
    background: #F4F0DF;
    padding: 10px 10px;
    border-radius: 10px;
    margin: 20px 0px;
  }
  .mainkycflex{
    display: flex;
    align-items: center;
    font-size: 12px;
    img{
        margin-right: 10px;
    }
  }
  .backshow{
    cursor: pointer;
  }


  /* --------------- MY Account -----------------*/


  .myaccountcard{
    position: relative;
    background: #fff;
    border-radius: 20px;
    padding: 40px 0px;
    min-height: 90vh;
  }
  /* @media(min-width:1300px){
    .myaccountcard{
        position: absolute;
        background: #fff;
        border-radius: 20px;
        padding: 40px 0px;
        width: 100%;
        height: 100%;
      }
  } */
  .myaccountflex{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 40px;
    position: relative;
  }
  .myaccountborder{
    border-bottom: 1px solid #ddd;
    margin-bottom: 20px;
  }
  .myaccountpadding{
    padding: 0px 40px;
  }
  .accountpre{
    cursor: pointer;
    span{
        margin-left: 10px;
        font-weight: 600;
    }
  }
  .accountimgfixed{
    position: absolute;
    right: 40px;
    cursor: pointer;
  }
  .myaccountradio{
    margin-top: 20px;
    padding: 0px 40px;
  }
  .accountbtndertails{
    /* position: absolute; */
    padding: 0px 40px;
    bottom: 30px;
    width: 100%;
  }
  @media(min-width:1600px){
    .accountbtndertails{
        position: absolute !important;
        padding: 0px 40px;
        bottom: 20px;
        width: 100%;
      }
  }
  .myaccountbtnflex{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .kycheadingmain{
    margin: 20px 0px 10px 0px;
    position: relative;
  }
  .btnflexcommon{
    display: flex;
    gap: 20px;
    margin-top: 20px;
  }
  .saveandexitbtn{
    border: 1px solid #9578E0;
    border-radius:30px;
    height: 42px;
    padding: 10px 30px;
    background: #fff;
    cursor: pointer;
    font-weight: 600;
  }
  .accountnextbtn{
    border: 1px solid #9578E0;
    border-radius:30px;
    height: 42px;
    padding: 10px 30px;
    color: #fff;
    background: #66499D;
    cursor: pointer;
    font-weight: 600;
  }
  .accountnextbtnMob{
    border: 1px solid #9578E0;
    border-radius:30px;
    height: 42px;
    padding: 10px 17px;
    color: #fff;
    background: #66499D;
    cursor: pointer;
    font-weight: 600;
  }


/* Loan Detail Page */
  .loanfulfillment{
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  .loaninput{
    display: flex;
    align-items: center;
    margin-right: 50px;
    input{
      margin-right: 10px;
      width: 16px;
      height: 16px;
    }
}
div.loaninput label {
    font-size: 12px;
  }
  
  .material {
    position: relative;
    font-size: 10px;
    height: 42px;
    margin-bottom: 16px;
    .form-input {
      border: 1px solid #e9e8e8;
      border-radius: 8px;
      box-shadow: 0 0 0;
      width: 100%;
      padding: 10px 15px;
      /* font-weight: 500; */
      font-size: 13px;
      height: 42px;
      &:focus {
        ~ label {
          &:before {
            top: -8px;
            font-size: 12px;
            background: #ffffff;
          }
        }
      }
      &:not(:placeholder-shown) {
        ~ label {
          &:before {
            top: -8px;
            font-size: 12px;
            background: #ffffff;
          }
        }
      }
    }
    
  }
  .error-msg-txt{
    color: red;
  }

  .datepicker input {
    width: 100%;
    border: 1px solid #e9e8e8;
    border-radius: 8px;
    box-shadow: 0 0 0;
    width: 100%;
    padding: 11px 15px;
    font-weight: 400;
    font-size: 12px;    
    background: url("https://static.ambak.com/saathi/1723460511791.svg") no-repeat right 7px center;
    background-size: 20px 20px;
    height: 42px;
}
.property-identified{
    display: flex;
    align-items: center;
    margin-bottom: 30px;
}

.is-obligation{
    display: flex;
    align-items: center;
    margin-bottom: 2px;
}


 /* --------------- MY Tools -----------------*/


 .mytoolscard{
    padding: 30px;
 }
 .offercalculator{
    background: #fff;
    border-radius: 20px;
    padding: 40px;
    min-height: 80vh;
 }
 .payoutcalculator{
    background: #fff;
    border-radius: 20px;
    padding: 40px;
    min-height: 90vh;
 }
 .payoutheading{
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 20px;
 }
 .loanoffercibil{
    font-size: 14px;
    font-weight: 500;
 }
 .offerloanflex{
    display: flex;
    /* justify-content: space-between; */
    gap: 40px;
    margin-bottom: 40px;
    overflow: hidden;
    overflow-x: auto;
 }
 .offercalcard{
    display: flex;
    gap: 20px;
    width: 25%;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding:20px;
    width: 50%;
 }
.loanofferbtn{
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 130px;
    background: #66499D;
    border-radius: 30px;
    padding: 10px;
    height: 36px;
    color: #fff;
    border: none;
    cursor: pointer;
    margin-top: 10px;
}
.offerlistcard{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 40px;
    border: 1px solid #ddd;
    border-radius: 10px;
    background: #fff;
    width: 25%;
}
.offerloanheading{
    font-size: 14px;
    font-weight: 500;
    margin: 20px 0px;
}
.offercommonbtn{
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #66499D;
    background: #fff;
    border-radius: 30px;
    width: 100%;
    max-width: 120px;
    height: 36px;
    cursor: pointer;
}
.loancalculatorcard{
    background: #fff;
    padding: 40px;
    border-radius: 20px;
}
.payoutcard{
    display: flex;
    gap: 80px;
    margin-bottom: 40px;
}
.flotleft{
    width: 50%;
}
.flotright{
    width: 45%;
}
.payoutcalculate{
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #473391;
    height: 42px;
    font-size: 14px;
    font-weight: 500;
    padding: 10px 70px;
    color: #fff;
    border: none;
    cursor: pointer;
    border-radius: 15px;
    margin: 70px auto 0 auto;    
}
.payoutrightcard{
    border: 1px solid #ddd;
    border-radius: 20px;
    padding: 40px 20px;
    box-shadow: 0px 2px 14px 0px #0000000F;
}
.payoutheadingright{
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 38px;
    span{
        color: #66499D;
    }
}
.payoutlistone{
    background: #E7F0FC;
    border-radius: 20px;
    padding:10px 20px;
    font-size: 11px;
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 30px;
    font-weight: 500;
    width: 50%;
    margin-right: 30px;
}
.payoutlisttwo{
    background: #EBF6E1;
    border-radius: 20px;
    padding:10px 20px;
    font-size: 11px;
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 30px;
    font-weight: 500;
    width: 50%;

}
.payoutlistthree{
    background: #FEF4D9;
    border-radius: 20px;
    padding:10px 20px;
    font-size: 11px;
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;
    font-weight: 500;
    width: 48%;

}
.payoutbankcard{
    border: 1px solid #ddd;
    padding: 20px 0px;
    box-shadow: 0px 3px 8px 0px #0000000A;
    border-radius: 20px;
    width: 31%;
    max-height: 180px;
    min-height: 180px;
}
.payoutcommon{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 20px 10px 20px;
    border-bottom: 1px solid#ddd;
}
.payoutamount{
    font-size: 10px;
}
.payoutprice{
    font-size: 14px;
    font-weight: 600;
}
.bankofferlist{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 16px;
}
.banklist{
    display: flex;
    width: 33%;
    padding: 10px;
}
.showbank{
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 9px;
    img{
        width: 20px;
        height: 20px;
    }
}
.allbankflexcard{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 35px;
}
.morebankbtn{
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #6147FF;
    border-radius: 10px;
    height: 20px;
    padding:10px;
    font-size: 10px;
    cursor: pointer;
}
.flexbankshowbtn{
    display: flex;
    align-items: center;
    justify-content: center;
}
.allshowbank{
    display: flex;
    font-size: 14px;
    font-weight: 500;
    gap: 10px;
    img{
        width: 30px;
        height: 30px;
    }
}
.banklistshowbtn{
    display: flex;
    align-items: center;
    justify-content: center;
    background: #66499D;
    border-radius: 20px;
    height: 42px;
    padding: 10px 30px;
    cursor: pointer;
    border: none;
    color: #fff;
    min-width: 200px;
    margin-top: 50px;
}

/* ----------------------newpayoutCal-------------- */
.PayoutCalmain{
    padding: 30px;
    display: flex;
    gap: 20px;
        width: 100%;
        /* grid-gap: 20px;
        gap: 20px; */
        /* min-height: 100vh; */
}
.payoutCalLeft{
    width: 70%;
    background: #fff;
    border-radius: 20px;
    padding: 40px;
    min-height: 90vh;
}
.payoutRight{
    width: 27%;
    background: #fff;
    border-radius: 20px;
    padding: 40px;
    min-height: 90vh;   
}
.newpayoutheading{
    font-size: 24px;
    font-weight: 600;
    margin: 0 auto;
    text-align: center;
}
.PayoutlistCont{
 display: flex;
 /* justify-content: center; */
 /* flex-wrap: wrap; */
 margin-top: 60px;
 margin-left: 30px;
}
.PayoutlistCont1{
    display: flex;
    /* justify-content: center; */
    /* flex-wrap: wrap; */
    margin-top: 10px;
    margin-left: 30px;
}
.payoutTop{
    display: flex;
    justify-content: space-between;
    /* font-size: 18px; */
    font-weight: 600;
    margin-bottom: 20px;
}
.EnterloanamtCont{
    border: 1px solid rgb(209, 209, 209);
    display: flex;
    padding: 10px;
    border-radius: 16px;

}
.savebtnDiv{
    align-items: center;
    display: flex
;
    margin-left: 10px;
}
.bottomContFix{
    border: 1px solid rgb(209, 209, 209);
    display: flex;
    flex-direction: column;
    padding: 10px;
    border-radius: 10px;
    background: #E7EFFB;
    position: sticky;
    width: 100%;
    bottom: 6px;
    font-size: 10px;
    margin-top: 10px;

}
.bulbContflx{
    display: flex;
    align-items: center;
    margin-bottom: 2px;
}
.bulbimgRes{
    width: 22px;
    height:22px
}
.PayoutbankContmain{
    border: 1px solid rgb(209, 209, 209);
    padding: 10px 20px 10px 15px;
    border-radius: 16px;
    align-items: center;
    margin-top: 20px;
}
.MainBottomCont{
    height: 60.5vh;
    overflow-y: auto;
    margin-top: 10px;
}
.PayoutbankCont{
    display: flex;
    padding: 10px 20px 10px 15px;
    justify-content: space-between;
    border-radius: 10px;
    align-items: center;
    /* margin-top: 20px; */
}
.liContDiv{
    padding: 6px;
    background: #F9F2E3;
    border-radius: 10px;
    display: flex;
    border: 1px solid rgb(230, 230, 230);

}
.ULCONT{
    list-style-type: disc;
    display: flex;
    padding: 5px 5px;
    font-size: 10px;
}
.ULCONT li {
    list-style: disc;
    margin-left: 10px;
    
}
.LeftBankAMt{
    font-size: 13px;
    font-weight: 500;
}
.RightBankAMt{
    width: 45%;
    display: flex;
    justify-content: space-between;
    font-size: 10px;
    
}
.amountPercnt{
    font-size: 13px;
    font-weight: 600;
}
.underlinetext{
    text-decoration: underline;
    cursor: pointer;
    margin-left: 22px;
    /* display: contents; */
}
.MobContMain{
    display: none;
}
.tooltipContainerpyt{
    max-height: 50vh;
    overflow: hidden;
    scroll-behavior: auto;
    overflow-y: auto;
}
.tooltipContainerpyt1{
    max-height: 100vh;
    overflow: hidden;
    scroll-behavior: auto;
    overflow-y: auto; 
    width: 100%;
}
.searchBANKCONT{
    padding: 30px 0 0 25px;
}
.clearbtnspytCont{
    display: flex;
    width: 260px;
    justify-content: space-between;
}
.clearbtnspytCont1{
    display: flex ;
        width: 100%;
        justify-content: space-evenly;
        position: absolute;
        bottom: 7%;
        border-top: 1px solid #d5d4d4;
        padding-top: 19px;
        box-shadow: 0px -8px 8px -4px #00000026;
        /* box-shadow: 0px 0px 8px 0px #00000026; */

}
.clrbtnpyt{
    border: 1px solid #66499D;
    padding: 10px;
    border-radius: 20px;
    color: #66499D;
    background-color: #fff;
}
.searchbtnpyt{
    padding: 10px 40px;
    border-radius: 20px;
    border: 1px solid #66499D;
color: #fff;
    background: #66499D;

}
.checkCont{
    font-size: 14px;
    margin: 5px 0 20px 5px;
    display: flex;
    align-items: center;
    font-weight: 400;
}
.checkCont input {
    transform: scale(1.5); /* Increase size */
    margin-right: 30px;
}
.checkCont1{
    font-size: 14px;
    margin: 5px 0 20px 30px;
    display: inline;
    align-items: center;
    font-weight: 400;
}
.checkCont1 input {
    transform: scale(1.5); /* Increase size */
    margin-right: 30px;
}
.MAINbankContCHECK{
    overflow-y: auto;
    /* background: antiquewhite; */
    max-height: 73vh;
}
.bankContCHECK{
    margin: 5px 0 10px 0 ;
}
.bottomoverlay {
    position: fixed;
    inset: 0;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: flex-end;
    z-index: 1000;
    
  }
  
  .bottomfixheight {
    max-height: 500px;
    overflow-y: auto;
  }
  
  .bottommodal {
    width: 100%;
    max-width: 600px;
    background: #fff;
    border-radius: 16px 16px 0 0;
    padding: 20px;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.2);
    position: relative;
  }
  
  .bottomcloseButton {
    position: absolute;
    top: 12px;
    right: 16px;
    font-size: 24px;
    cursor: pointer;
  }
  .mobilinput{
    margin-top: 30px;
}
.searchMaindiv{
    display: flex;
    width: 500px;
    justify-content: right;
}

@media (max-width: 900px) {
    .MobContMain{
        display: block;
    }
    .PayoutCalmain{
        display: block;
        padding: 10px;
    }
    .payoutCalLeft{
        width: 100%;
        padding: 10px;

    }
    .PayoutlistCont{
        margin: 10px 0 0 0;
        flex-wrap: wrap;
    }
    .PayoutlistCont1{
        margin: 10px 0 0 0;
    }
    .payoutlistone{
        margin-right: 0;
        margin-bottom: 18px;
        width: 100%;
    }
    .payoutlisttwo{
        margin-bottom: 18px;
        width: 100%;
    }
    .payoutlistthree{
        width: 100%;
    }
    .payoutRight{
        display: none;
    }
    .PayoutbankContmain{
        margin-top: 10px;
        padding: 0;
    }
    .EnterloanamtCont{
        display: none;
    }
    .MobileEnterloanamtCont{
        padding: 10px;
    border: 1px solid rgb(230, 230, 230);
    border-radius: 16px;   
    margin-bottom: 10px; 
    }
    .amontdiv{
        display: flex;
        justify-content: space-between;
        display: flex;
        padding: 0 10px;
        font-size: 13px;
        font-weight: 600;
        margin: 10px 0;
    }
    .Textspanpyt{
        font-size: 9px;
        font-weight: 400;

    }
    .searchMaindiv{
        display: none;
    }
    .PayoutbankCont{
        /* margin: 12px 12px 12px 0; */
        padding: 11px 12px 11px 8px;
    }
    .payoutTop{
        margin-bottom: 5px;
    }
    .RightBankAMt{
        width: 50%;
    }
    .MainBottomCont{
        height: 49vh;
    }
    .searchContPYT{
        display: flex;
    }
    .morefilterbtnmob{
        display: flex;
        align-items: center;
        justify-content: center;
        background: transparent;
        font-weight: 500;
        font-size: 12px;
        border: 1px solid rgba(205, 199, 199, 1);
        background: #F2EFFF;
        cursor: pointer;
        border-radius: 10px;
        padding: 7px 20px;
        font-size: 12px;
        height: 42px;
        color: #5F5F5F;
        width: 60px;
        span{
            margin-left: 0px;
        }
    }
    .mobileDeatilsInput{
        z-index: 1000;
        height: 40vh;
        position: fixed;
        bottom: 0;
        background-color: #989898;
        width: 100%;
        border-radius: 20px 20px 0 0;
        padding: 20px;
    }
    .underlinetext{
        margin-top: 1px;
        margin-left: 8px;
    }
    .payoutcalculate{
        margin: 23px auto 0 auto;
    }
    .mobilinput{
        margin-top: -20px;
    }
    .bankaContScreenMobl{
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
        background: rgba(255, 255, 255, 2); /* Transparent dark background */
        display: flex;
        z-index: 1000;
        /* padding: 20px 0  0 30px ; */
    }
    .okbtnMobil{
        background-color: #473391;
        width: 80%;
    }
    .newpayoutheading{
        font-size: 20px;
        width: 60%;
        margin-top: 30px;
    }
    .bulbimgRes{
        width: 50px;
        height:50px
    }
  
}
/* ----------------------newpayoutCal-------------- */


.slidernew-container {
    width: 100%;
    margin: 20px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .slidernew-labels {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  /* .styled-slider {
    -webkit-appearance: none; 
    appearance: none;
    width: 100%;
    height: 7px;
    border-radius: 5px;
    outline: none;
    cursor: pointer;
  } */
 

/*progress support*/


    input[type=range].styled-slider {
        height: 0px;
        -webkit-appearance: none;
        outline: none;
      }
      
      /*progress support*/
      input[type=range].styled-slider.slider-progress {
        --range: calc(var(--max) - var(--min));
        --ratio: calc((var(--value) - var(--min)) / var(--range));
        --sx: calc(0.5 * 2em + var(--ratio) * (100% - 2em));
      }
      
      input[type=range].styled-slider:focus {
        outline: none;
      }
      
      /*webkit*/
      input[type=range].styled-slider::-webkit-slider-thumb {
        -webkit-appearance: none;
        width: 2em;
        height: 2em;
        border-radius: 1em;
        background: #fff;
        border: 3px solid #66499D;
        margin-top: calc(max((1em - 1px - 1px) * 0.5, 0px) - 2em * 0.5);
      }
      
      input[type=range].styled-slider::-webkit-slider-runnable-track {
        height: 10px;
        border: none;
        border-radius: 0.5em;
        background: #252525;
        box-shadow: none;
      }
      
      input[type=range].styled-slider::-webkit-slider-thumb:hover {
        background: #fff;
      }
      
      input[type=range].styled-slider:hover::-webkit-slider-runnable-track {
        background: #fff;
        border-color: #66499D;
      }
      
      input[type=range].styled-slider::-webkit-slider-thumb:active {
        background: #fff;
      }
      
      input[type=range].styled-slider:active::-webkit-slider-runnable-track {
        background: #fff;
        border-color: #66499D;
      }
      
      input[type=range].styled-slider.slider-progress::-webkit-slider-runnable-track {
        background: linear-gradient(#66499D, #66499D) 0/var(--sx) 100% no-repeat, #c5c4c4;
      }
      
      input[type=range].styled-slider.slider-progress:hover::-webkit-slider-runnable-track {
        background: linear-gradient(#66499D, #66499D) 0/var(--sx) 100% no-repeat, #c5c4c4;
      }
      
      input[type=range].styled-slider.slider-progress:active::-webkit-slider-runnable-track {
        background: linear-gradient(#66499D, #66499D) 0/var(--sx) 100% no-repeat, #c5c4c4;
      }
      
      /*mozilla*/
      input[type=range].styled-slider::-moz-range-thumb {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: #fff;
        border: 3px solid #66499D;
      }
      
      input[type=range].styled-slider::-moz-range-track {
        height: 10px;
        border: none;
        border-radius: 0.5em;
        background: #D9D9D9;
        box-shadow: none;
      }
      
      input[type=range].styled-slider::-moz-range-thumb:hover {
        background: #fff;
      }
      
      input[type=range].styled-slider:hover::-moz-range-track {
        background: #fff;
        border-color: #66499D;
      }
      
      input[type=range].styled-slider::-moz-range-thumb:active {
        background: #fff;
      }
      
      input[type=range].styled-slider:active::-moz-range-track {
        background: #f5f5f5;
        border-color: #66499D;
      }
      
      input[type=range].styled-slider.slider-progress::-moz-range-track {
        background: linear-gradient(#66499D, #66499D) 0/var(--sx) 100% no-repeat, #c5c4c4;
      }
      
      input[type=range].styled-slider.slider-progress:hover::-moz-range-track {
        background: linear-gradient(#66499D, #66499D) 0/var(--sx) 100% no-repeat, #c5c4c4;
      }
      
      input[type=range].styled-slider.slider-progress:active::-moz-range-track {
        background: linear-gradient(#66499D, #66499D) 0/var(--sx) 100% no-repeat, #c5c4c4;
      }
      
      /*ms*/
      input[type=range].styled-slider::-ms-fill-upper {
        background: transparent;
        border-color: transparent;
      }
      
      input[type=range].styled-slider::-ms-fill-lower {
        background: transparent;
        border-color: transparent;
      }
      
      input[type=range].styled-slider::-ms-thumb {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: #66499D;
        border: 3px solid #66499D;
        box-shadow: 0 0 2px black;
        margin-top: 0;
        box-sizing: border-box;
      }
      
      input[type=range].styled-slider::-ms-track {
        height: 10px;
        border-radius: 1em;
        background: #D9D9D9;
        border: 1px solid #66499D;
        box-shadow: none;
        box-sizing: border-box;
      }
      
      input[type=range].styled-slider::-ms-thumb:hover {
        background: #66499D;
      }
      
      input[type=range].styled-slider:hover::-ms-track {
        background: #e5e5e5;
        border-color: #66499D;
      }
      
      input[type=range].styled-slider::-ms-thumb:active {
        background: #66499D;
      }
      
      input[type=range].styled-slider:active::-ms-track {
        background: #f5f5f5;
        border-color: #66499D;
      }
      
      input[type=range].styled-slider.slider-progress::-ms-fill-lower {
        height: max(calc(1em - 1px - 1px), 0px);
        border-radius: 0.5em 0 0 0.5em;
        margin: -1px 0 -1px -1px;
        background: #66499D;
        border: 1px solid #66499D;
        border-right-width: 0;
      }
      .input-wrapper {
        position: relative;
        display: inline-block;
        width: 150px; 
      }
      
      .input-field {
        width: 100%;
        padding-left: 35px; 
        box-sizing: border-box;
        font-size: 16px;
      }
      
      .percentage-symbol {
        position: absolute;
        right: 20px; 
        top: 50%;
        transform: translateY(-50%);
        color: rgb(155, 150, 150); 
        font-size: 18px;
        pointer-events: none;
      }
      .input-wrapper {
        position: relative;
        display: inline-block;
        width: 150px;
      }
      
      .input-field {
        width: 100%;
        padding-left: 51px; 
        box-sizing: border-box;
        font-size: 16px;
      }
      .Year-symbol {
        position: absolute;
        right: 20px; 
        top: 50%;
        transform: translateY(-50%);
        color: rgb(155, 150, 150);
        font-size: 15px;
        pointer-events: none; 
      }
      .rupee-symbol {
        position: absolute;
        right: 115px; 
        top: 50%;
        transform: translateY(-50%);
        color: rgb(155, 150, 150);
        font-size: 15px;
        pointer-events: none; 
      }
      
      
      input[type=range].styled-slider.slider-progress:hover::-ms-fill-lower {
        background: #66499D;
        border-color: #66499D;
      }
      
      input[type=range].styled-slider.slider-progress:active::-ms-fill-lower {
        background: #66499D;
        border-color: #66499D;
      }

.emibox {
    padding: 10px 0px;
    border-radius: 20px;
}
.mainamount {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 15px;
    font-weight: 500;
    /* margin: 20px 0px 10px 0px; */
    input{
    width: 150px;
    text-align: center;
    height: 42px;
    padding: 5px;
    font-size: 14px;
    font-weight: 600;
    border-radius: 30px;
    border: 1px solid lightgray;
    }
}
.range {
    input {
        width: 100%;
        color: #473391;
    }
}
.tenureflex{
    display: flex;
    gap: 20px;
}
.tenuretabs{
    display: flex;
    border: 1px solid #ddd;
    height: 32px;
    width: 150px;
    border-radius: 30px;
    font-size: 12px;
}
.tenureyearly{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
}
.tenureyearly.active{
    background: #000;
    color: #fff;
    border-radius: 20px 0px 0px 20px;
}
.tenuremonthly{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
}
.tenuremonthly.active{
    background: #000;
    color: #fff;
    border-radius: 0px 20px 20px 0px;
}
.emirightcard{
    border: 1px solid #ddd;
    border-radius: 20px;
    box-shadow: 0px 2px 14px 0px #0000000F;
}
.loanflex{
    display: flex;
}
.emichartcard{
    width: 50%;
    padding: 50px 30px;
    border-right: 1px solid#ddd;
}
.principalamount {
    display: flex;
    align-items: center;
    font-size: 14px;
    margin-bottom: 20px;
}
.principalamount span {
    width: 16px;
    height: 16px;
    background: #66499D;
    display: inline-block;
    border-radius: 2px;
    margin-right: 10px;
}
.interestamount{
    display: flex;
    align-items: center;
    font-size: 14px;
}
.interestamount span{
    width: 16px;
    height: 16px;
    background: #1FC593;
    display: inline-block;
    border-radius: 2px;
    margin-right: 10px;
}
.emicommon{
 width: 50%;  
}
.loanemiamount{
    padding:50px 0px 30px 35px ;
    border-bottom: 1px solid #ddd;
}
.loanemiamount:last-child{
    border-bottom: none;
}
.loanemifont{
    font-size: 13px;
}
.loanpricefont{
    font-size: 17px;
    font-weight: 600;
    margin-top: 10px;
}
.pie-chart {
    position: relative;
    width: 180px;
    height: 180px;
}

.pie {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: conic-gradient(green 0% 44%, purple 44% 100%);
    position: relative;
}


.value-green {
    position: absolute;
    top: 50%;
    right: 15%;
    transform: translate(-50%, -50%);
    font-size: 18px;
    font-weight: bold;
    color: white;
}


.value-purple {
    position: absolute;
    top: 50%; 
    left: 30%;
    transform: translate(-50%, -50%);
    font-size: 18px;
    font-weight: bold;
    color: white;
}

/* --------------- Cibil -----------------*/

.mytoolcibilcard {
    display: flex;
    width: 100%;
    gap: 20px;
    min-height: 95vh;
    }
    .mytoolhelpcard {
        display: flex;
        width: 100%;
        gap: 20px;
        min-height: 85vh;
        }
    .toolcibilleft {
    display: flex;
    flex-direction: column;
    width: 70%;
    background: #fff;
    /* padding: 40px; */
    border-radius: 20px;
    }
    .helpleft {
    display: flex;
    flex-direction: column;
    width: 70%;
    background: #fff;
    padding: 40px;
    border-radius: 20px;
    }
    .otpcontainer {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
    }
    .otpcontainer input[type="number"] {
        flex: 1;
        width: 40px;
        height: 40px;
        margin: 0 5px;
        text-align: center;
        font-size: 16px;
        border: 1px solid #DDDDDD;
        border-radius: 10px;
    }
    .otpcard{
        display: flex;
        flex-direction: column;
        text-align: center;
        margin: auto;
        max-width: 400px;
    }
    .otpenter{
        font-size: 13px;
        margin-bottom: 10px;
    }
    
    .otpresend{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 20px;
    }
    .otpagainsend{
        cursor: pointer;
        color: #473391;
    }
    .scorecard{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .showcibilscore{
        display: flex;
        justify-content: space-between;
        width: 260px;
    }
    .speedometer{
        height: 160px !important;
    }
    .segment-value{
        display: none !important;
    }
    .current-value{
        display: none !important;
    }
    .cibilreportheading{
        font-size: 24px;
        color:#473391;
        font-weight: 600;
        margin-bottom: 20px;
    }
    .cibilreportsub{
        font-size: 13px;
        margin-bottom: 20px;
    }
    .cibilnewpara{
        text-align: center;
        font-size: 13px;
        width: 50%;
         margin: 0 auto 30px auto;
    }
    .viewreportbtncibil{
        display: flex;
        align-items: center;
        gap: 5px;
        justify-content: center;
        border: none;
        height: 32px;
        padding: 10px 20px;
        color: #473391;
        font-weight: 600;
        background: #fff;
        border-radius: 10px;
        cursor: pointer;
        margin-right: 40px;  
        text-decoration: underline;  
    }
    .notfetchcibil{
        font-size: 24px;
        color:#E44862;
        font-weight: 600;
        margin: 20px 0px; 
    }
    .offerbtncommon{
        display: flex;
        align-items: center;
        gap: 20px;
        justify-content: center;

    }
    .downloadcibil{
        display: flex;
        align-items: center;
        gap: 5px;
        justify-content: center;
        border: 1px solid #473391;
        height: 42px;
        min-width: 200px;
        background: #fff;
        border-radius: 10px;
        cursor: pointer;
    }
    .toolcibilpaddingtop{
        padding: 40px 40px 0px 40px;
    }
    .toolcibilpadding{
        padding: 40px;
    }
    .toolbgcolor{
        background: linear-gradient(180deg, #C1E8DF 25.12%, #FFFFFF 100%);
        border-radius: 20px;
    }
    .backoffer{
        display: flex;
        gap: 10px;
        align-items: center;
    }
    .offercost{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        background-color: #D6E7E4;
        padding: 10px 20px;
        border-radius: 10px;
        margin: 20px 0px;
    }
    .offerheadingben{
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 20px;
    }
    .offerlistingicon{
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 20px;
    }
    .offerlistingcoapp{
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        margin-top: 20px;
        li{
            width: 33%;
            margin-bottom: 20px;
        }
    }
    .offerlistingname{
        font-size: 14px;
        font-weight: 600;
    }
    .cibiltoolcibilleft {
        display: none;
        }
    .toolcibilright {
    width: 25%;
    background: #fff;
    padding: 40px 20px;
    border-radius: 20px;
    }
    .mytoolcibilflexcard{
        width: 100%;
        max-width: 500px;
        margin: auto ;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .cibilcheckbtn{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 42px;
        background: #66499D;
        color: #fff;
        border: none;
        width: 100%;
        max-width: 200px;
        min-width: 200px;
        border-radius: 10px;
        cursor: pointer;
        margin-top: 20px;
    }
    .cibilheadingmain{
        font-size: 18px;
        font-weight: 500;
        text-align: center;
        margin: 20px 0px;
    }
    .cibilsubheadingmain{
        font-size: 14px;
        text-align: center;
        margin-bottom: 20px;
    }
    .mytoolverification{
        width: 100%;
        margin: 40px 0px auto 0px ;
        display: flex;
        flex-direction: column;
    }
    .cibilsendconsent {
        margin-top: auto;
        padding-bottom: 20px;
      }
      .cibilsendbottom{
        display: flex;
        justify-content: center;
        align-items: flex-end;
        width: 100%;
        border-top: 2px solid #ddd;
      }
      .offerterms{
        display: flex;
        align-items: flex-end;
        margin-top: auto;
        padding: 0px 0px 20px 40px;
        a{
            color: #473391;
            text-decoration: underline;
            margin: 0px 5px 0px 5px;
            cursor: pointer;
        }
      }
    .verificationheadingmain{
        font-size: 18px;
        font-weight: 600;
    }
    .primaryaccount{
        padding: 5px 10px;
        background: #1AC18F;
        color: #fff;
        border-radius: 8px;
        font-size: 10px;
    }
    .coapplicantaccount{
        padding: 5px 10px;
        font-size: 10px;
        color: #fff;
        border-radius: 8px;
        background: #E09C4B;
    }
    .buttonflexmobile{
        display: flex;
        gap: 20px;
        justify-content: center;
    }
    .cibilnewlead{
        border: 1px solid #66499D;
        background: #fff;
        height: 42px;
        border-radius: 10px;
        cursor: pointer;
        width: 100%;
        max-width: 200px;
        min-width: 200px;
        margin-top: 20px;
    }
    .allcustomerinfo{
        background: #EEECFF;
        padding: 10px 20px;
        border-radius: 8px;
        font-size: 12px;
        margin-bottom: 20px;
    }
    .cibilloanflex {
        display: flex;
        align-items: center;
        gap: 20px;
        margin: 20px 0;
        flex-wrap: wrap;
    }
    .cibilhometab {
        border: 1px solid #ddd;
        height: 42px;
        width: 100%;
        max-width: 130px;
        cursor: pointer;
        padding: 10px 20px;
        border-radius: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: border 0.3s, color 0.3s;
        white-space: nowrap;
    }
    .cibilhometab.active {
        border: 1px solid #473391;
        color: #473391;
    } 
    .cibilhometab img {
        margin-right: 8px;
    }
    .cibilgiffile{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
    .cibillink{
        display: flex;
        align-items: center;
        gap: 10px;
        a{
            color: #000;
            font-weight: 600;
        }
    }
    .cibillinkcopy{
        color: #473391;
        cursor: pointer;
    }
    .otherlinkshow{
        margin: 20px 0px;
        li{
         border-bottom: 1px solid #ddd;
         padding-bottom: 20px;
         margin-bottom: 20px;
        }
        a{
            color: #000;
        }
    }
    .otherlinkshow1{
        margin: 30px 0px;
        border-top: 1px solid #ddd;
        padding: 30px 0 0 0;
        li{
         padding-bottom: 20px;
         margin-bottom: 20px;
        }
        a{
            color: #000;
        }
    }
    .cibiltextwidth{
        width: 100%;
        max-width: 90%;
    }
    .otherlinkslist{
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
       
    }
    .cibilreportflex{
        display: flex;
        width: 100%;
        gap: 20px;
        min-height: 100vh;
        }
    .cibilleftpale{
        display: flex;
        flex-direction: column;
        width: 40%;
        background: #fff;
        padding: 40px 20px;
        border-radius: 20px;
    }
    .cibilrightpale{
        width: 60%;
        background: #fff;
        padding: 40px 20px;
        border-radius: 20px;
    }
    .cibilscorecard{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .cibilcircle {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;
    }
    .cibilcircle img {
        display: block;
    }
    .cibilcircle .textoverlay {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        color: #333;
        font-size: 20px;
    }
    .cibilscoreheading{
        font-weight: 600;
    }
    .cibilsubstatus{
        font-size: 12px;
        /* color: #473391; */
    }
    .cibilupdatedstatus{
        font-size: 12px;
        margin-bottom: 10px;
        color: gray;
    }
    .cibilcolor{
        color: #473391;
    }
    .cibildownloadbtn{
        display: flex;
        align-items: center;
        justify-content: center;
        background: #66499D;
        border: none;
        border-radius: 30px;
        width: 60%;
        margin: 20px 0px;
        height: 42px;
        color: #fff;
        cursor: pointer;
    }
    .cibiladownloadbtn{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
    .cibilotherinfocard{
        background: #E5E2FF;
        padding: 20px;
        border-radius: 10px;
    }
    .cibilreportlist{
        font-size: 10px;
        margin: 10px 0px;
    }
    .cibilreportsummery{
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 20px;
    }
    .reportsummarylist{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 20px 0px;
    }
    .reportsummarylistaccount{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 20px 0px;
        padding-bottom: 20px;
        border-bottom: 1px solid #ddd;
    }
    .cibilmonthly{
        font-size: 10px;
    }
    .cibilprice{
        font-size: 14px;
        font-weight: 600;
    }
    .cibiltotalcount{
        color:#473391;
        font-size: 16px;
        font-weight: 600;
    }
    .cibilcouuntborder{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .cibilaccount{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-left: 1px solid #ddd;
        width: 20%;
    }
    .activeacountshow{
        font-weight: 600;
    }

    
    /* ----------------------- Loan Offer Calcultor ---------------------- */

    .myloanoffercard{
        width: 100%;
        background: #fff;
        padding: 40px 40px 80px 40px;
        border-radius: 20px;
        min-height: 90vh;
    }
    .myloanofferflex{
        display: flex;
        gap: 40px;
        justify-content: space-between;
    }
    .loanofferleftpanle{
        display: flex;
        flex-direction: column;
        min-height: 80vh;
        width: 50%;
    }
    .loanofferrightpanle{
        display: flex;
        flex-direction: column;
        min-height: 80vh;
        width: 40%;
    }
    .loanofferheading{
      font-size: 24px;
      font-weight: 600;
       width: 100%;
       margin: 40px 0px auto 0px;
    }
    .loanoffersubheading{
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-size: 24px;
        font-weight: 600;
        margin-top: 40px;
    }
    .loanmarginauto{
        width: 100%;
        margin: 40px 0px auto 0px;
    }
    .loanoffersub{
        color: #6E55B8;
    }
    .getstartedbtn{
        display: flex;
        align-items: center;
        justify-content: center;
        background: #6E55B8;
        width: 100%;
        height: 42px;
        color: #fff;
        border-radius: 10px;
        border: none;
        cursor: pointer;
        margin-top: 50px;
    }
    .commonloancard{
        display: flex;
        flex-direction: column;
        min-height: 70vh;
    }
    .loanofferallbox{
        flex: 1;
        display: flex;
        flex-direction: column;
    }
    .mainheadingall{
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 20px;
    }
    .allheadingincome{
        font-size: 13px;
        font-weight: 600;
        margin-top: 20px;
    }
    .loanoffertop, .loanofferbottom {
        padding: 20px;
    }
    .loanoffermiddle {
        flex: 1;
        padding: 20px;
        justify-content: center;
        display: flex;
        /* align-items: center; */
        flex-direction: column;
    }
    .loanbtnnext{
        background: #66499D;
        height: 42px;
        padding: 10px 30px;
        color: #fff;
        cursor: pointer;
        border: none;
        cursor: pointer;
        border-radius: 30px;
        width: 100%;
        max-width: 150px;
    }
    .loanadditional{
        color: #473391;
        font-size: 14px;
        font-weight: 600;
        cursor: pointer;
        margin-bottom: 20px;
    }
    .loanremoveoffer{
        cursor: pointer;
        text-align: end;
        color:#E05858;
    }
    .loannewlead{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .loannewleadbtn{
        display: flex;
        align-items: center;
        justify-content: center;
        background: #66499D;
        height: 42px;
        border: none;
        width: 100%;
        max-width: 250px;
        cursor: pointer;
        color: #fff;
        border-radius: 10px;
        margin-top: 40px;
    }
    .loanleadskip{
        color: #66499D;
        border-bottom: 1px solid #66499D;
        margin-top: 20px;
        cursor: pointer;
    }
    .formerror{
        color: red;
        font-size: 10px;
        margin-left: 5px;
    }
    .formerrorinput{
        color: red;
        font-size: 10px;
        margin-left: 5px;
        margin-top: -20px;
    }

    .label-required::after {
        content: "*";
        color: red;
    }
    .form-required{
        color: red;
    }

    .paddingLeftHeading{
        padding-left: 16px;
    }
    .leftmyleadcontainer{
        display: flex;
        gap: 10px;
        a{
            color: #000;
            font-size: 20px;
        }
    }
    .seractfieldinput{
        display: flex;
        align-items: center;
        border: 1px solid rgba(214, 204, 204, 1);
        height: 42px;
        background: #fff;
        border-radius: 10px;
        /* margin-top: 20px; */
        padding: 5px 10px;
        input{
            border: none !important;
            height: 40px;
            padding: 0px 5px;
            &:hover{
                border: none;
            }
        }
    }
    .nodataflex{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .nodatacard{
        margin-top: 100px;
        width: 600px;
    }
    .nodataheading{
        font-size: 18px;
        font-weight: 600;
        margin: 40px 0px 20px 0px;
    }
    .nodatapara{
        font-size: 12px;
        color: #000;
        opacity: 0.5;
        text-align: center;
    }

/* To hide input type number arrows */

/* For chrome, safari, edge, opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* For firefox */
/* input[type=number] {
  -moz-appearance: textfield;
} */

.noteTag{
    font-size: 12px;
    border-radius: 4px;
    background-color: #8170ff;
    color: #fff;
    padding: 2px 5px;
    margin-left: 10px;
}

.payout-scheme{
    width: 100%;
    display: flex;
    flex-direction: row;
    gap:20px;
 }


/*--------------------------------- ApproveInvoice css Start   ----------------------------  */


.approvecard{
    display: flex;
    padding: 10px;
    align-items: center;
    gap: 10px;
    background: #fff;
    border-radius: 10px;
    margin-bottom: 20px;
}
.emptycontent{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}
.approveinovicecard{
    display: flex;
}
.approveleftcard{
    width: 65%;
    padding: 25px;
    background: #fff;
    border-radius: 20px;
    min-height: 85vh;
    margin-right: 20px;
}
.approverigthcard{
    position: relative;
    width: 33%;
    background: #fff;
    border-radius: 20px;
    border: 1px solid #D6D0D0;
    min-height: 85vh;
}
.invoicedateraised{
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 11px;
    background-color: #FFF6D0;
    padding: 10px;
    border-radius: 10px;
    margin: 0px 15px 20px 15px;
}
.generateinvoice{
    display: flex;
    align-items: center;
    justify-content: center;
    background: #6E55B8;
    height: 42px;
    font-size: 12px;
    padding: 10px 20px;
    border-radius: 10px;
    border: none;
    width: 100%;
    color: #fff;
    cursor: pointer;
    margin-top: 20px;
    a{
        color: #fff;
    }
}
.regenerateinvoice{
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    font-size: 11px;
    height: 42px;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #6E55B8;
    width: 100%;
    color: #6E55B8;
    cursor: pointer;
    margin-top: 20px;
}
.generateinvoiceafter{
    display: flex;
    gap: 20px;
    margin-bottom: 50px;
}
.queryraise{
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    border-top: 1px solid #ddd;
    font-size: 12px;
    font-weight: 500;
    bottom: 0px;
    gap: 20px;
    padding: 20px;
    width: 100%;
    cursor: pointer;
}
.otpcenter{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
}
.otp-container {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}
.otp-container input[type="number"] {
    /* display: flex; */
    width: 40px !important;
    height: 40px;
    margin: 0 5px;
    text-align: center;
    font-size: 16px;
    border: 1px solid #DDDDDD;
    border-radius: 10px;
}
.resendotp{
    color:#6E55B8 ;
    cursor: pointer;
    font-size: 12px;
    margin-top: 20px;
}

.payout-scheme{
    width: 100%;
    display: flex;
    flex-direction: row;
    gap:20px;
 }
    
 .payout-scheme-box-first{
    background: #fff;
    border-radius: 20px;
    padding: 40px;
    width: 78%;
    min-height: 90vh;
 }
 .payout-scheme-box-second{
    background: #fff;
    border-radius: 20px;
    padding: 20px;
    width: 22%;
    min-height: 90vh;
 }

 .other-card{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #EAEAEA;
    padding: 10px;
 }
 .other-card span,a{
    font-size: 12px;
    font-weight: 500;
 }

 @media (max-width: 1500px) {
    .other-card {
        font-size: 12px;
    }
    .payout-scheme-box-first{
        width: 75%;
    }
    .payout-scheme-box-second{
        width: 25%;
    }
    .scheme-card-item-value{
        font-size: 12px !important;
    }
}
 .other-card>a,.other-card>span:nth-child(2){
    color: #6E55B8;
    cursor: pointer;
 }
 .scheme-item{
    display: flex;
    flex-direction: column;
    padding: 10px;
    width: max-content;
 }
 .scheme-card-item-label{
    font-size: 12px;
    font-weight: 400;
 }
 .scheme-card-item-value{
    font-size: 14px;
    font-weight: 600;
 }
 .scheme-card{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding:3%;
    column-gap: 5%;
    cursor: pointer;
    /* gap: 180px; */
    border: 1px solid #EAEAEA;
    border-radius: 20px;
 }
 .scheme-bottom-border{
    border-bottom: 1px solid #EAEAEA;
 }
 .scheme-main-card{
    display: flex;
    flex-direction: column;
 }
 .schemeinnercard{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    margin-bottom: 40px;
}
.badges {
    grid-column: span 2;
    display: flex;
    gap: 8px;
}

.badge {
    padding: 4px 15px;
    border-radius: 5px;
    font-size: 12px;
    font-weight: 500;
    color: #fff;
    display: inline-block;
}

.badge.special {
    background-color: #1AC18F; /* Green */
}

.badge.fulfillment {
    background-color: #6E55B8; /* Purple */
}
.badge.standard {
    background-color: #DAB873; /* yellow */
}
.scheme-card-divider {
    grid-column: span 2;
    border-bottom: 1px solid #eee;
    margin: 12px 0;
}

.scheme-contest{
    display: flex;
    overflow-x: scroll;
    gap:15px;
}
.scheme-contest img{
    border-radius: 10px;
    
}

.scheme-popup-heading{
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 30px;
}

.popup-table-container{
    height: 600px;
    overflow-y: scroll;
}
.popup-table-container table{
    overflow: auto;
}

.popup-table-container table thead {
    background-color: #473391;
    border-radius: 10px !important;
    height: 55px;
}
.popup-table-container .table-header {
    width: 50%;
    border-right: 2px solid white;
    font-size: 14px;
    font-weight: 500;
    padding-left: 30px;
}
.popup-table-container td {
    /* margin: 8px 0;  */
    padding-left: 30px;
    text-align: left;
    font-weight: 400;
}

.scheme-detail-card{
    border:1px solid #EAEAEA;
    border-radius: 15px;
    padding: 10px 20px;
}
.scheme-detail-heading{
    font-size: 18px;
    font-weight: 600;
    /* margin-bottom: 20px; */
}
.scheme-detail-card-heading{
    font-size: 16px;
    font-weight: 500;
    /* margin-bottom: 20px; */
}
.inner-scheme-card{
    background-color: #6E55B8;
    border-radius: 16px;
    color: white;
    padding: 0px 10px;
    display: flex;
    height: 60px;
    /* width: 350px; */
    gap:40px;
    min-width: max-content;
}

.inner-scheme-card>div{
    background-color: #6E55B8;
    border-radius: 16px;
    color: white;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap:5px;
}
.inner-scheme-card>div>div:first-child{
    font-weight: 600;
}

.inner-scheme-card-two{
    background-color: #6E55B8;
    border-radius: 16px;
    color: white;
    padding: 10px 20px;
    display: flex;
    height: 60px;
    width: 350px;
    gap:100px;
}

.scheme-detail-card-category-one{
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    border:1px solid #EAEAEA;
    border-radius: 20px;
    padding: 20px;
    gap:10px;
}
.scheme-detail-category-two{
    display: flex;
    flex-wrap: wrap;
    column-gap:20px;
    row-gap: 10px;
}
.scheme-detail-card-category-one>div:first-child{
    display:flex;
    flex-wrap: wrap;
    gap: 25px;
    margin-top: 10px
}
.slab{
    font-size: 10px;
}

.scheme-bank-card{
    margin-top: 10px;
    border-radius: 8px;
    border:1px solid #EAEAEA;
    padding: 5px ;
    padding-left: 10px;
    display: flex;
    align-items: center;
    height: 50px;
    gap:10px;
    width: max-content;
    min-width: 200px;
    padding-right: 10px;
}
.scheme-bank-card>img{
    object-fit: contain;
}

.scheme-bank-card>div{
    font-size: 12px;
    font-weight: 500;
}

.scheme-rewards-tabs {
    display: flex;
    gap: 15px;
  }
  .scheme-rewards-tabs button {
    padding: 8px 30px;
    background: #F0EFF5;
    border: none;
    cursor: pointer;
    border-radius: 80px;
    /* font-weight: bold; */
  }
  .scheme-rewards-tabs .active {
    background: #6E55B8;
    color: #fff;
    font-weight: 600;
  }
  .scheme-rewards-table-container {
    margin-top: 16px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 16px;
  }
  .scheme-rewards-table-container table {
    width: 100%;
    border-collapse: collapse;
  }
  .scheme-rewards-table-container th,.scheme-rewards-table-container td {
    padding: 8px 12px;
    border-bottom: 1px solid #EAEAEA;
    text-align: center;
    color: #000;
    font-size: 14px;
    font-weight: 500;
  }
  .scheme-detail-reward{
    margin: 20px 0px;
  }
  .scheme-rewards-table-container table,.scheme-rewards-table-container thead {
    background-color: #EDEDED;
    border-radius: 8px !important;
    height: 30px;
}
.scheme-terms h2 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 16px;
  }


  .scheme-terms h3 {
    font-size: 20px;
    font-weight: 600;
    margin-top: 24px;
    margin-bottom: 8px;
  }
  .scheme-terms ul {
      padding-left: 20px;
      margin-bottom: 16px;
    }
    .scheme-terms li {
        font-size: 16px;
        list-style-type: disc;
    line-height: 1.6;
    margin-bottom: 8px;
    margin-left: 10px;
  }

.scheme-thank-head{
    margin-top: 70px;
    font-size: 30px;
    font-weight: 600;
    text-align: center;
}
.scheme-thank-image{
    display: block;
    margin: 50px auto 50px auto;
    width: 70%;
}
.scheme-thank-footer{
    display: flex;
    justify-content: space-around;
}
.scheme-thank-footer span{
    font-size: 20px;
    font-weight: 500;
}
.scheme-thank-footer div{
    display: flex;
    align-items: center;
    gap:10px;
}
.mb-20{
    margin-bottom: 20px;
}
.scheme-heading{
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.bell-icon{
    background-color: #fff;
    border-radius: 50%;
    padding:8px 14px;
    margin-bottom: 10px;
    cursor: pointer;
}
.disbursenew {
    display: flex;
    gap: 5px;
    align-items: center;
  }
  .othercolor{
    color: #000;
    font-size: 9px;
    font-weight: 500;
}
.lender-list{
    height: 62px;
    display: flex;
    gap:20px;
    align-items: center;
    border-bottom: none;
}
.lender-list img{
    width: 30px;
    object-fit: contain;
}
.remove-scroll-bar{
    overflow-y: hidden;
}

.popup-table-container::-webkit-scrollbar {
    width: 18px;
    margin-left: 10px;
    /* Width of the scrollbar */
}

.popup-table-container::-webkit-scrollbar-track {
    background: #DFDFDF;
    /* Light gray background for the track */
    border-radius: 5px;
    /* width: 10px; */
    /* Rounded corners */
}

.popup-table-container::-webkit-scrollbar-thumb {
    background-color: #FFFFFF;
    /* Dark purple color for the thumb */
    border-radius: 8px;
    border: 3px solid #DFDFDF;
    /* width: 15px; */
    /* Rounded corners for the scrollbar */
}

.popup-table-container::-webkit-scrollbar-thumb:hover {
    background-color: #FFFFFF;
    /* Slightly darker shade on hover */
}
.loading-custom{
    height: 100vh;
    margin-left: 33%;
    margin-top: 30%;
    position: absolute;
}

.nodataflexpage{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-radius: 25px;
}
.nodatacardpage{
    margin-bottom: 40px;
}
.disburse-container{
    position: relative;
    display: inline-block;
    cursor: pointer;
    background: #E44862;
    border-radius: 0px 6px 6px 0px;
    padding: 5px;
    margin-left: 5px;
  }
  .disburse-card{
    position: absolute;
    top: 30px;
    left: 0%;
    padding: 16px;
    transform: translatex(-50%);
    width: 200px;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border: 1px solid #EBEBEB;
    border-radius: 20px;
    color: #000;
    margin-left: 10px;
    z-index: 100;
    opacity: 1;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
  }
  .disburse-container:hover .disburse-card {
    opacity: 1;
    visibility: visible;
  }
  .disbursecommon{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    font-size: 10px;
  }
  .disbursepending{
    color: #cf1938;
  }
  .transaction{
    color: #268a1d;
  }
  .etaDate{
    color: #f06717;
  }
  .updatepdddate{
    cursor: pointer;
    text-decoration: underline;
  }
  .updatepddbtn{
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    height: 42px;
    border: none;
    background: #473391;
    color: #fff;
    cursor: pointer;
    border-radius: 30px;
    margin-top: 20px;

  }
.loanstatuscolor{
    color: #000;
    font-size: 13px;
    font-weight: 500;
}
.loanstatuslist li{
        padding-left: 10px;
}
.otherinfocolor span {
    margin-right: 8px;
}
.disburse-container:hover span img {
    transform: rotate(180deg);
    transition: transform 0.3s ease;
}
.commonimgflex{
    display: flex;
    gap: 10px;
    align-items: center;
}
.disbursecommonnew{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    font-size: 13px;
  }
  /* ---------------------------APF SEARCH-------------------------------------- */
  .details-container {
    width: 100%;
    margin: 10px;
  }
  
  .details-row {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
  }
  
  .detail-item {
    width: 25%;
    padding: 30px 10px;
    box-sizing: border-box;
    height: 20vh;
  }
  
  .bank-list {
    display: flex;
    flex-wrap: wrap;
  }
  
  .bank-item {
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 5px;
  }
  .apiDiv{
    margin-top: 20px;
  }
  .bank-logo img {
   min-height: 35px;
    margin-right: 10px;    
  }
  .apfsearchconta{
    display: flex;
    background-color: #fff;
    border-radius: 20px;
    align-items: self-end;
    padding: 20px 0;
  }
  .apfsearchconta2{
    display: flex;
    background-color: #fff;
    border-radius: 20px;
    align-items: self-end;
     margin-top: 10px;
  }
  .labelsize{
    font-size: 16px;
    margin-bottom: 2px;
    font-weight: 500;
    color: #000;
  }
  .no-city-message{
    font-size: 14px;
    /* text-align: center; */
    padding-left: 4px;
  }
  .modalPOPUP{
    position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  }
  .expFeebck{
    font-size: 18px;
    font-weight: 500;

  }
  .modalfeedback {
    /* display: flex;
    flex-direction: column;
    position: relative;
    height: 40vh;
    align-items: center;
    justify-content: center; */
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 767px;
    position: relative;
    /* height: 240px; */
}
.crossfeedback{
    display: flex;
    justify-content: space-between;
}
.crosssign{
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
}
.firstSectionFedback{
    text-align: center;
    margin-top: 40px;

}

.apftopline{
    font-size: 24px;
    font-weight: 500;
    margin: 0;
}

.thumbContainer{
    text-align: center;
    margin-top: 25px;
}
.thumbs-up-button{
    border: none;
    font-size: 16px;
    cursor: pointer;
    margin: 0 10px;
    border-radius: 6px;
    background: #D9D9D9;
    padding: 8px 28px;
    color: #AAAAAA;

}
.thumbs-down-button:hover, 
.thumbs-up-button:hover {
    background: #473391;
    color: white;
}
.thumbs-up-button.active,
.thumbs-down-button.active {
    background: #473391; /* Active background color */
    color: white; /* Active text color */
    border: 2px solid #473391;
}
.thumbupfeeback{
    color: #ff0000;
    padding: 3px 4.5px;
    border-radius: 50%;
    margin: 0 15px 0 0 ;
    background-color: white;
}
.thumbs-down-button {
    border: none;
    font-size: 16px;
    cursor: pointer;
    margin: 0 10px;
    border-radius: 6px;
    background: #D9D9D9;
    padding: 8px 28px;
    color: #AAAAAA;

}

.feedback-input {
    margin-top: 20px;
    text-align: center;
}
.textcontfeback{
    text-align: left;
}

.FeedbackInput {
    width: 100%;
    max-width: 720px;
    height: 140px;
    margin: 10px 0;
    padding: 10px;
    border-radius: 10px;
    /* border: 1px solid rgb(228, 228, 228); */
    border: 1px solid #C2C2C2;
    resize: none;
    text-align: left;
}

.feedback-input button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
}

 /* ----------------------------------------------- Input css start ------------------------------ */

.commoninput {
    position: relative;
    width: 100%;
    margin-bottom: 28px;
  }
.input-wrapper {
    display: flex;
    align-items: center;
    position: relative;
}
.commoninput .newinput {
    border: 1px solid #BAB7B7;
    font-family: Poppins, sans-serif;
    padding: 0px 10px;
    width: 100%;
    height: 42px;
    border-radius: 12px;
    font-size: 12px;
    transition: all 0.2s ease;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: white;
}
.right-content {
    position: absolute;
    right: 10px;
    top: 10px;
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
    color: #473391;
    cursor: pointer;
}
.commoninput .newinput:focus{
    border-color: #ddd;
    outline: none;
}
.commoninput input:focus + label,
.commoninput input:not(:placeholder-shown) + label {
    top: -8px;
    font-size: 12px;
    color: rgba(0, 0, 0, 1);
    background: #ffffff;
    padding: 0 5px;
    z-index: 1;
}

  /* For iOS specific styling */
  
  @supports (-webkit-touch-callout: none) {
    .priceinput input:not(:-webkit-autofill) + label {
        top: 10px; 
    }
    .priceinput input:not(:-webkit-autofill):focus + label,
    .priceinput input:not(:-webkit-autofill):not(:placeholder-shown) + label {
        top: -10px;
    }
  }
  
  .commoninput .newlabel {
    position: absolute;
    top: 10px;
    left: 10px;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.7);
    transition: all 0.2s ease;
    pointer-events: none;
  }
  .commoninput .newinput:focus::placeholder {
    color: transparent;
  }
  
  .commoninput .newinput::placeholder {
    transition: all 0.2s ease;
  }
  .commoninput .newinput:not(:placeholder-shown)::placeholder {
    margin-top: 12px;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.37);
  }
  /* .commoninput input::-webkit-input-placeholder {
    color: transparent;
  } */
  
  .commoninput .newinput::-moz-placeholder {
    color: transparent;
  }
  
  .commoninput .newinput:-ms-input-placeholder {
    color: transparent;
  }
  
  .commoninput .newinput:-moz-placeholder {
    color: transparent;
  }
  .required-asterisk {
    color: red;
    margin-left: 4px;
  }
  /* .error-border {
    border: 1px solid red !important;
  } */
  
  .error-message {
    color: red;
    position: absolute;
    font-size: 10px;
  }
/*--------------------------- Accordion Css Start -------------------*/


.faqli {
    padding: 0px;
    margin-bottom: 10px;
    display: block;
    transition: transform 0.5s;
    border-bottom: 1px solid #ddd;
}
.faqli-open {
    border-bottom: 1px solid #ddd;
    background: #FFFFFF;

}
.faqli-open .accordionHeight {
    height: auto;
    margin-top: -10px;
}
.faqli-open .faqicon {
    display: flex;
    justify-content: center;
    align-items: center;
    transform: rotate(180deg);
    transition: transform 0.5s;
}
.faqheading {
    display: flex;
    align-items: center;
    margin-bottom: 0;
    padding: 20px 0px;
    font-weight: 600;
    font-size: 12px;
    color: #000;
    border-radius: 10px;
    cursor: pointer;
    position: relative;
}
.faqicon {
    position: absolute;
    right: 10px;
    margin-right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.5s;
}
.faqheading-open {
    background: #fff;
    border-radius: 10px;
}
.accordionDetail {
    font-size: 12px;
    color: #000 !important;
    opacity: 0.6;
}


  /*----------- Help And Support css Start ---------------*/

  .faqsContainer {
    overflow-y: auto;
    height: 60vh;
    scrollbar-width: none; /* Hide scrollbar for Firefox */
}

.faqsContainer::-webkit-scrollbar {
    width: 8px;
    display: none; /* Hide scrollbar by default */
}

.faqsContainer:hover::-webkit-scrollbar {
    display: block; /* Show scrollbar when hovering */
}

.faqsContainer:hover {
    scrollbar-width: thin; /* Show thin scrollbar for Firefox when hovering */
}
  .helpheadingnew{
    display: flex;
    font-size: 20px;
    font-weight: 600;
    padding: 20px 0px 0px 30px;
    justify-content: space-between;

}
.helpAndSupportIconContainer{
    border: 2px solid #FF8060;
    background-color: #FBB38A7A;
    margin-right: 100px;
    padding: 2px 5px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    cursor: pointer;
}
  .commonbtn {
    background: #F5F0FF;
    border-radius: 4px;
    width: 240px;
}
.self-btn {
    padding: 10px 20px;
    background: #F5F0FF;;
    border: none;
    font-size: 14px;
    color: #000;
    cursor: pointer;
    border-radius: 4px;
    width: 120px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2); /* Adds a soft shadow */

}

.self-btn.active {
    background:#6C5CA7 ;
    color: #fff;
    border-radius: 4px;
}
.self-btn:hover {
    border-radius: 4px;
    transform: scale(1.01); /* Slightly enlarges the button */
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2); /* Adds a soft shadow */
}

  .callusheading{
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 20px;
  }
.maincardbm{
    padding: 20px 0px;
    border-top: 1px solid #ddd;
}
  .mainheaadingbm{
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  .subheadingbm{
    font-size: 14px;
    margin-bottom: 5px;
  }
  .searchfaqflex{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }
.sibh{
    display: flex;
    align-items: center;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 0px 10px;
    height: 44px;
    input{
        border: none;
    }
    input:hover{
        border: none;
    }
    input:focus {
        border: none;
    }
}
.sibhh{
    display: flex;
    align-items: center;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 0px 10px;
    height: 44px;
    input{
        border: none;
    }
    input:hover{
        border: none;
    }
    input:focus {
        border: none;
    } 
}
.searchiconforword{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    width: 40px;
    border-left: 1px solid #ddd;
    cursor: pointer;
}
.supportteambox{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #F5F0FF;
    padding:20px;
    border-radius: 10px;
    margin-bottom: 20px;
}
.supportteambox2{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #F5F0FF;
    padding:20px;
    border-radius: 10px;
    margin-bottom: 20px;
    height: 70vh;
}
.chatsupportpara{
    font-size: 14px;
    font-weight: 600;
    width: 50%;
}
.chatsupportpara2{
    font-size: 18px;
    font-weight: 600;
    width: 80%;
    margin-bottom: 5vh;
    text-align: center;
}
.chatus{
    display: flex;
    align-items: center;
    justify-content: center;
    background: #6C5CA7;
    width: 150px;
    border-radius: 10px;
    color: #fff;
    height: 42px;
    cursor: pointer;
}
.flexdate{
    display: flex;
    align-items: center;
    gap: 10px;
}
.opensupportstatus{
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    width: 100px;
    padding: 5px;
    border-radius: 4px;
    background: #4BBCE0;
}
.pendingsupportstatus{
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    width: 100px;
    padding: 5px;
    border-radius: 4px;
    background: #E09C4B;
}
.resolvedsupportstatus{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    color: #fff;
    width: 100px;
    padding: 5px;
    border-radius: 4px;
    background: #1AC18F;
}
.chatmaincontianer{
    position: relative;
}
.chatboxcard{
    padding-bottom:20px;
    border-bottom: 1px solid #ddd;
}
.chatissue{
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 500;
    gap: 10px;
}
.flexid{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
}
.arrowsize{
    font-size: 20px;
    cursor: pointer;
}
.datefixes{
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 50%;
    top: 100px;
    background: #ECECEC;
    padding:10px 20px;
    z-index: 2;
    border-radius: 10px;
    font-size: 10px;
}
.chatmiddle{
    height: 55vh;
    overflow-y: auto;
}

.chatmaincontianer {
    display: flex;
    flex-direction: column;
    /* height: 100vh;  */
  }
  
  /* Middle section (Scrollable chat content) */
  .chatmiddle {
    margin-bottom: 120px;
    margin-top: 20px;
    padding: 15px;
    overflow-y: auto;
    flex-grow: 1;
    /* height: calc(100vh - 140px); */
  }
  
  /* Bottom section (Fixed input area) */
  .chatbottom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
  }
  .foot_cont {
    flex-shrink: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    padding:6px  5px;
    width: 100%;
    min-height: 8.5vh;
    border-top: 1px solid #8F8F8F;
    margin-top: 20px;
}
.send_input{
    border: none;
    width: 100%;
}
.send_input textarea {
    font-family: poppins;
    margin: 5px;
    padding: 5px;
    border: none ;
    width: 96%;
    background-color: transparent;
    border-right: 1px solid #8F8F8F;
    resize: none;
    overflow-y: auto; 
    scroll-behavior: smooth;
    height: 30px;
    max-height: 100px;
  }
.send_input textarea:focus {
    outline: none;
}
.send_arrow{
    cursor: pointer;
}
.send_input_cont{
    display: flex;
    width: 100%;
    padding: 0px 10px;
    align-items: center;
    text-align: center;
    margin: 0 auto;
    margin: 5px;
    border: 1px solid #8F8F8F;
    border-radius: 23px;
    background: #fff;
}

.send_input_cont_partner{
    display: flex;
    width: 78%;
    padding: 0px 10px;
    align-items: center;
    text-align: center;
    margin: 0 auto;
    margin: 5px;
    border: 1px solid #8F8F8F;
    border-radius: 23px;
    background: #fff;
}
.flexendchat{
    display: flex;
    justify-content: flex-end;
}
.send_messs {
    align-self: flex-end; 
    display: inline-block; 
    width: auto;
    max-width: 80%;
    padding: 12px 12px 7px 12px;
    border: 1px solid #DCD5D5;
    border-radius: 12px 12px 0px 12px;
    font-size: 13px;
    color: white;
    margin: 10px 0 10px 0;
    background: #6C5CA7;
    
}
.recievd_messs {
    display: table;
    width: auto;
    max-width: 80%;
    padding: 12px 12px 7px 12px;
    border: 1px solid #DCD5D5;
    border-radius: 12px 12px 12px 0;
    font-size: 13px;
    color: #000;
    margin: 20px 0 0 0;
    background-color: #f9f9f9;
}
.unReadMessage{
    height: 18px;
    width: 18px;;
    background-color: #FF8A88;
    position: absolute;
    border-radius: 50%;
    top: 0;
    right: 15px;
    opacity: 50;
    color: white;
    text-align: center;
}
.offercalcardimg{
    width: 15%;
}
.webverificationStatus{
    margin-left: 10%;
}
.filterhidemobile{
    display: none;
}
.commonheading{
    font-size: 18px;
    font-weight: 600;
}
@media screen and (max-width: 968px) {
    .pendingstatusmobile{
        margin-left: 30% !important;
    }
    .logincontainer {
        display: block;
        padding-right: 0px;
      }
      .newloginimg{
        display: none;
      }
      .leftheadinglogin{
        padding: 0;
        text-align: center;
      }
      .shadow-box {
        padding: 16px;
        width: 85%;
      }
      .left-side{
        margin-top: 20px;
      }
      .newtabsContainer{
        /* display: block; */
        overflow-x: auto;
        overflow-y: hidden;
      }
      .table-container{
        overflow-x: auto;
      }
    .myaccountbtnflex{
        display: block;
    }
  
    .myleadcontainer{
        display: block;
        padding: 0;
    }
    .newtabContent{
        white-space: nowrap;
    }
    .apfsearchconta{
        display: block;
    }
    thead th{
        white-space: nowrap;
    }
    td{
        white-space: nowrap;
    }
    .offercalcard{
        display: block;
        width: 100%;
        padding: 20px;
        text-align: center;
        margin: 0 0 20px 0;
    }
    .offerloanflex{
        gap: 20px;
        width: 100%;
        display: block;
        margin-bottom: 0px;
    }
    .loanofferbtn{
        display: block;
        margin: 0 auto;
        max-width: 115px;
    }
    .offerlistcard{
        display: block;
        width: 100%;
        text-align: center;
        padding: 16px 40px;
        margin-bottom: 20px;
    }
    .loanoffercibil{
        margin-bottom: 10px;
    }
    .newleadcreateflexbtn{
        display: block;
    }
    .verificationStatus{
        /* margin-left: 30%; */
        right: 50%;
    }
    .myaccountpadding{
        /* padding: 0 15px; */
        padding: 0 1px;
    }
    .topcontainer{
        display: block;
        padding: 5px;
    }
    .lead_bodycontainer{
        display: block;
    }
    .leftcontainer{
        width: 100%;
    }
    .rightcontainer{
        width: 100%;
    }
    .innerleftcont_left{
        padding: 10px 10px;
    }
    .innerleftcont_right{
        padding: 10px 10px;
    }
    .leftbottomcontainer1{
        /* display: block; */
        padding: 5px 0;
    }
    .user_info{
        width: 100%;
        justify-content: space-evenly;
        border: none;
    }
    .loan_id_cont{
        width: 50%;
        margin: 10px 0;
    }
    .loan_id_cont{
        border: none;
    }
    .mytoolcibilcard{
        display: flex;
        flex-direction: column;
    }
    .toolcibilleft{
        width: 100%;
    }
    .toolcibilright{
        width: 100%;
        margin-top: 5px;
        padding: 10px;
    }
    .payoutcard{
        display: block;
        margin-bottom: 5px;
    }
    .payoutcalculator{
        padding: 5px;
    }
    .flotleft{
        width: 100%;
        margin-bottom: 5px;
    }
    .flotright{
        width: 100%;
    }
    .pie-chart{
        margin: 0 0 0 0;
        width: 150px;
        height: 150px;
    }
    .emichartcard{
        padding: 40px 0;
    }
    .mytoolscard{
        padding: 15px;
    }
    .myloanofferflex{
        display: block;
    }
    .myloanoffercard{
        /* background-color: #e5eaf7; */
        padding: 10px;
    }
    .loanofferleftpanle{
        width: 100%;
    }
    .loanofferheading{
        margin: 5px 0 0 0;
    }
    .loanofferrightpanle{
        width: 100%;
        min-height: 55vh;
    }
    .loanoffermiddle{
        display: block;
    }
    .payout-scheme{
        display: block;
    }
    .payout-scheme-box-first{
        width: 100%;
        padding: 10px;


    }
    .payout-scheme-box-second{
        width: 100%;
        margin-top: 5px;

    }
    .schemeinnercard{
        display: block;
    }
    .scheme-card {
        display: block;
    }
    .scheme-item {
        width: auto;
    }
    .badges{
        justify-content: space-between;
    }
    .offercalculator{
        padding: 20px;
        border-radius: 15px;
    }
    .loanofferleftpanle{
        min-height:0%;
    }
    .loanoffermiddle{
        padding: 0;
    }
    .detailstop{
        padding: 10px;
    }
    .LeadContainer{
        padding: 10px;
    
        }
    .leadcontainer{
        padding: 5px 5px;

    }
    .editleadnew{
        display: block;
        padding: 10px 10px;
        margin-top: 8px;
    }
    .righteditleadcont{
        width: 100%;
    }
    .rightmyleadcontainer{
        margin-bottom: 10px;
    }
    .leftbotmContTabs{
        display: flex;
    }
    .loan_id_cont{
        width: 90%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .move_login{
        justify-content: space-between;
        width: 97%;
    }
    .leftbottomcontainer1 div button{
        margin: 2px 8px;
        padding: 5px 12px;
        font-size: 10px;
    }
    .loanfulfillment{
        margin-bottom: 10px;
    }
    .loaninput{
        margin-right: 17px;
    }
    .payoutheading{
        margin-bottom: 15px;
    }
    .documentcard{
        padding: 10px;
    }
    .commontabsItem{
        min-width: 102px;
    }
    .lefttopcontainer_inner_left{
        min-width: 276px;
    }
    .lefttopcontainer_inner_right{
        min-width: 281px;
    }
    .lefttopcontainer{
        padding: 10px 8px
    }
    .modal-context {
        padding: 10px;
    }
    .loan_review_heading{
        padding: 6px 5px;
    }
    .details-row{
        display: block;
    }
    .detail-item{
        width: 100%;
        height: 0vh;
        display: flex;
        align-items: center;
    }
    .apiDiv{
        margin: 0;
    }
    .supportteambox{
        display: block;
        padding: 8px;
    }
    .chatsupportpara{
        width: 100%;
    }
    .toolcibilleft{
        padding: 5px;
        border-radius: 10px;
    }
    .commonbtn{
        display: flex;
        justify-content: space-around;
        width: 100%;
        margin-bottom: 5px;
        background-color: #fff;

    }
    .searchfaqflex{
        display: block;
    }
    .maincardbm{
        padding: 8px 0;
    }
    .offercalcardimg{
        width: 100%;
    }
    .tablecontainer{
        overflow-x: auto;
        margin: 12px 0;
        padding: 12px 0px
    }
    .newleadbutton button {
        font-size: 11px;
        height: 32px;
        padding: 1px 17px;
        border-radius: 11px;
    }
    .saveandexit{
        position: relative;
        margin-left: 11px;
    }
    .loan_review{
        padding: 6px 0;
    }
    .notes_contain{
        padding: 0 6px;
    }
    .doclistbtn {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 46px;
        padding: 0px 18px;
        font-size: 10px;
        border-radius: 10px;
        margin-top: 20px;
    }
    .updatestatusflex{
        justify-content: center;
        margin-top: 0;
    }
    .lefteditleadcont{
        width: 100%;
    }
    .newtabs{
        padding: 0 3px;
    }
    .table-container {
        margin: 4px;
    }
    .chatus{
        width: 100px;
        height: 33px;
    }
    .self-btn {
        margin: 10px 0;
        width: 115px;
        padding: 6px 20px;
        font-size: 13px;
    }
    .chatsupportpara{
        font-size: 13px;
    }
    .myaccountradio {
        margin-top: 13px;
        padding: 0px 10px;
    }
    .accountbtndertails{
        padding: 0 10px;
    }
    .loanemiamount{
        padding: 25px 0px 30px 5px;
    }
    .newloginimgkyc{
        display: none;
    }
    .tab-list {
        margin-bottom: 5px;
    }
    .offercommonbtn{
        margin: 0 auto;
    }
    .offerloanheading{
        margin: 10px 0;
    }
    .leadheading{
        margin-bottom: 5px;
    }
    .leadheadingPYT{
        margin-bottom: 5px;
    }
    .payoutrightcard{
        padding: 5px 10px;
    }
    .payoutheadingright{
        margin-bottom: 10px;
    }
    .loanoffersubheading{
        margin-top: 10px;
    }
    .getstartedbtn{
        margin-top: 0;
    }
    .receckbtn{
        width: 22%;
    }
    #toggleCheckbox:checked + #filterCard{
        padding: 7px;
        left: 7%;
        height: 0;
        border: 1px solid #c9c6c6;
    }
    #filterCard{
        width: 305px;
    }
    .filterhidemobile{
        display: block;
    }
    .pagination{
        justify-content: initial;
    }
    .pagination-bx {
  
        ul {
            justify-content: initial;
        }}
         
   
    .myaccountcard{
        padding: 20px 5px;
    }
    .accountimgfixed{
        right: 24px;
    }
    .popup-table-container{
        overflow-x: auto;
    }
    .popup-table-container table{
        overflow-x: auto;
    }
    .apfbtn_cont{
        margin-top: 10px;
        padding: 12px;
    }
    .loan_id_cont div button{
        padding: 5px;
    }
    .mytoolhelpcard {
        display: flex;
        flex-direction: column;
        }
        .helpleft{
            width: 100%;
            padding: 20px;
        }
        .scheme-thank-footer{
            display: flex;
            flex-direction: column;
            gap: 10px;
        }
        .callstatuscard{
            display:block;
            background-color: #fff;
            padding: 10px;
            width: 100%;
            position: fixed;
            left: 0px;
            bottom: 0px;
            box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.1);
        }
        .loanbtnnext{
            display: flex;
            justify-content: center;
            margin: 10px auto 0 auto;
        }
        .loanofferdetailsnew{
            display: block;
            }
            .loanofferdetailsnewleft{
                width: 100%;
            }
            .loanofferdetailsnewright{
                width: 100%;
            }
}

@media screen and (min-width: 750px) and (max-width: 968px) {
    .shadow-box {
        width: 70%;
    }
}

@media screen and  (max-width:768px){
    .__react_component_tooltip.show {
        left: 0 !important;
        width: 100vw;
    }
}
/* -------------------------------------------------*/
 .newoffercard{
    border: 1px solid #E6E6E6;
    background-color: white;
    border-radius: 16px;
    color: black;
    width: 100%; 
}
.offerCard {
    padding:16px;
    border-radius: 16px;
}
.pcofferCard{
    position: relative;
    display: flex;
    align-items: center;
    gap: 20px;
    background-color: white;
    border-radius: 16px;
    color: black;
    width: 100%; 
    border: 1px solid #E7E7E7;
    box-shadow: 0px 2px 4px 0px #0000000F;
}
.offerfastcard{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #F4F3DD;
    border-radius: 16px 16px 0px 0px;
    padding: 0px 10px;
}
.offerfast{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0px;
    gap: 10px;
    font-size: 10px;  

    @media screen and (min-width: 900px) {
        padding: 10px 12px;
    }
}
.offerfastpc{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    gap: 10px;
    background: #F4F3DD;
    border-radius: 30px;
    font-size: 10px;  

}
.offerrecommendedM{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    font-size: 10px;
    background: linear-gradient(269.79deg, #F9FBB9 -3.9%, #FF8D1B 124.76%);
    border-radius: 0px 16px 0px 16px
}
.offerfeescard{
    display: flex;
    align-items: center;
    background: #EFF7FD;
    padding: 5px 10px;
    border-radius: 30px;
    cursor: pointer;
    position: relative;
    width: 66%;
    justify-content: space-between;

}

.cardTop {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
}
.cardTopLeft {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 10px;
}


.cardValue {
    font-size: 13px;
    font-weight: 600;
    font-family: poppins;
    color: #000;
}
.tenurevalue{
    font-size: 11px;
    font-weight: 500;
    font-family: poppins;
    color: #000; 
}
.MOspan{
    font-weight: 400;
    font-size: 8px;
    color: #000;
}

.cardBottom {
    margin-top: 6px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
    gap: 5px;
    margin-bottom: 10px;
}
.cardDetailsBox {
    margin-top: 6px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    gap: 0px;
    padding: 0 16px;
}
.timeoffercard{
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 10px;
    background: #F2EFFF;
    border-radius: 30px;
    padding: 5px 10px;
}

.arrowButton {
    font-size: 16px;
}

.applyNowButton {
    display: flex;
    width: 100px;
    height: 30px;
    align-items: center;
    justify-content: center;
    background: #473391;
    color:  #fff;
    padding: 8px;
    font-size: 10px;
    font-weight: 400;
    border: none;
    border-radius: 30px;
    cursor: pointer;
}

.offerCard1{
    padding: 16px 0;
}
/* .offerCard1:hover{
    background: #f0f0f0;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    .applyNowButton{
        color:white;
        border:2px solid #30206A;
        background: linear-gradient(92.41deg, #5D45B8 2.42%, #30206A 99.01%);
    }
} */

.applyNowButton:hover{
    background-color: #263F49;
    color:white;
}

.breviewfees{
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 10px;
    /* // color: #6147FF; */
    cursor: pointer;
}

.offerlistingfees1{
    width: 100%;
    /* // border-top: 1px solid #ddd; */
    /* // margin-top: 20px; */
    padding-top: 10px;
}

.feesheading{
    font-size: 12px;
}

.feessubheading{
    font-size: 12px;
    font-weight: 500;
    color: #555;
}


.submitbtnnewlead1{
    width: 60%;
    max-width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #473391;
    margin-top: 20px;
    color: #fff;
    padding: 10px 30px;
    height: 42px;
    border: none ;
    border-radius: 30px;
    cursor: pointer;
}

.feesContainer{
    height: 100%;
    display:flex;
    flex-direction: column;
    justify-content: space-between;
}

.feesTopHeadingSection{
    display:flex;
    justify-content: space-between;
    align-items: center;
}

.feesTopHeadingSection>div{
    font-size: 16px;
    font-weight: 500;
}

.feesContextContainer{
    margin-top:20px;
}

.closeModalCross{
    font-size: 26px;
    cursor: pointer;
}
.hidepcbankoffer{
    display: block;
    width: 100%;
}
.hidemobilebankoffer{
    display: none;
}

@media(min-width:900px) {
    .cardBottom {
        margin-top: 20px;
        justify-content: space-between;
        grid-template-columns: repeat(5, auto);
    }

    .bankName {
        font-size: 12px;
        font-weight: 500;
        font-family: poppins;
        width: 120px;
    }

    .offerCard {
        padding: 16px 24px;
    }

    .arrowButton {
        display: none;
    }

    .applyNowButton {
        display: flex;
    }

    .cardHeading {
        font-size: 12px;
    }

    /* // .cardValue {
    //     font-size: 16px;
    // } */

    .feesContextContainer{
        margin-top:0px;
    }

    .feesContainer{
        gap:30px;
    }
    .hidepcbankoffer{
        display: none;
    }
    .hidemobilebankoffer{
        display: block;
        width: 100%;
    }
    .offerrecommendedM{
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: absolute;
        top: 0px;
        right: 0px;
        padding: 10px;
        font-size: 10px;
        background: linear-gradient(269.79deg, #F9FBB9 -3.9%, #FF8D1B 124.76%);
        border-radius: 0px 16px 0px 16px
    }
    .feescardfast{
        display: flex;
        justify-content: space-between;
        gap: 20px;
        padding: 0px 0px 20px 16px;
    }
    .offerfeescard {
        display: flex;
        align-items: center;
        background: #EFF7FD;
        padding: 10px 20px;
        border-radius: 30px;
        cursor: pointer;
        position: relative;
      }
      
      .offershowfees {
        display: none;
        position: absolute;
        background: white;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        padding: 10px;
        z-index: 10;
        width: 100%;
        max-height: 300px;
        overflow-y: auto;
        left: 0;
        top: 100%;
      }
      
      .offerfeescard:hover .offershowfees {
        display: block;
      }
      .offerfeescard:hover svg {
        transform: rotate(180deg);
        transition: transform 0.3s ease;
      }

      .callstatuscard{
          display: none;   
      }

}
.offerSection {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    gap: 16px;
}
.offercallcard1{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #EFF7FD;
    border: 1px solid #473391;
    padding: 10px 10px 0px 10px;
    border-radius: 10px;
}
.offerneed{
    font-size: 12px;
    font-weight: 500
}
.offertalk{
    font-size: 10px;
}
.vendor-message{
    background: #EAE7FF;
    display: flex;
    flex-direction: row;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    padding: 10px 5px;
    opacity:0.7;
    color:black
}
.message-left{
    display: flex;
    flex-direction: row;
    align-items: center;
    width : 20%;
    opacity:1;
}
.vendor-text{
    margin: 0;
    opacity:1;
    
}
.i-icon{
    margin-left: 5px;
}